import { Routes } from "@/router";
import { createStore } from "vuex";

/* These are consistent with the evolve-app */
export enum RetirementDecision {
  Annuity = "Annuity",
  Drawdown = "Drawdown",
  LumpSum = "Lump Sum",
  UncrystalisedLumpSum = "Uncrystalised Lump Sum",
}

export enum DrawdownOption {
  NA = "not applicable",
  AQUA = "Aqua",
  RUBY = "Ruby",
  JADE = "Jade",
  ONYX = "Onyx",
  OPAL = "Opal",
}
/* Done */

export enum DrawdownStrategy {
  Aqua = "Aqua",
  Jade = "Jade",
  Onyx = "Onyx",
  Ruby = "Ruby",
  Opal = "Opal",
}

export class Nominee {
  public fullName = "";
  public relationship = "";
  public percentage = 0;

  public addressLine1 = "";
  public addressLine2 = "";
  public addressCity = "";
  public addressCounty = "";
  public addressCountry = "";
  public addressPostCode = "";
}

export class Scheme {
  public schemeAdministrator = "";
  public schemeName = "";
  public referenceNumber = "";
  public fundValue = 0;

  public addressLine1 = "";
  public addressLine2 = "";
  public addressCity = "";
  public addressCounty = "";
  public addressCountry = "";
  public addressPostCode = "";
}

export function defaultState(): any {
  return {
    admin: {
      uid: "",
    },
    data: {
      optionSelected: RetirementDecision.Drawdown,

      // For drawdown.
      drawdownStrategy: DrawdownStrategy.Aqua,
      totalFundValue: 0,
      taxFreeLumpSum: 0,
      grossAnnualIncome: 0,
      monthlyIncome: 0,
      taxFreeElement: 0,
      estimatedFundValueAt75: 0,

      // For lump sums.
      twentyFivePercentTaxFreeCash: 0,
      totalTaxableAmount: 0,
      taxDueOn20Percent: 0,
      taxAt20Percent: 0,
      taxDueOn40Percent: 0,
      taxAt40Percent: 0,
      taxDueOn45Percent: 0,
      taxAt45Percent: 0,
      netPayment: 0,

      // For both.
      dateOfBirth: "01/01/1956",
    },
    step1: {
      title: "",
      surname: "",
      forename: "",
      niNumber: "",
      maritalStatus: "",
      email: "",
    },
    step2: {
      telephoneNumber: "",
      address: {
        line1: "",
        line2: "",
        city: "",
        county: "",
        country: "",
        postCode: "",
      },
    },
    step3: {
      allFundsInvestedWithEvolve: "",
      schemes: [] as Scheme[],
      // schemeName: "",
      // policyNumber: "",
      // schemeAdministrator: "",
      // schemeAdministratorAddress: {
      //   postCode: "",
      //   line1: "",
      //   line2: "",
      //   city: "",
      //   county: "",
      //   country: "",
      // },
    },
    step4: {
      accountNumber: "",
      sortCode: "",
      rollNumber: "",
      accountHolderName: "",
    },
    step5: {
      benefitsExceedLifetimeAllowance: "",
      inReceiptOfBenefitsBefore2006: "",
      inReceiptOfBenefitsBefore2006Data: {
        schemeName: "",
        currentAnnualIncome: 0,
        dateBenefitCommenced: "",
      },
      inReceiptOfBenefitsAfter2006: "",
      inReceiptOfBenefitsAfter2006Data: {
        dateCommenced: "",
        schemeName: "",
        ltaPercentage: 0,
      },
      registeredForProtectionOrEnhancementToLifetimeAllowance: "",
      primaryProtection: "",
      fixedProtection: "",
      enhancedProtection: "",
      lifetimeAllowanceEnhancementFactors: "",
    },
    step6: {
      nominees: [] as Nominee[],
    },
    control: {
      loqate: "CJ83-JR94-DK44-MU36",
    },
    isDev: process.env.NODE_ENV === "development",
    isTest: process.env.NODE_ENV === "test",
  };
}

export default createStore({
  state: defaultState(),
  mutations: {
    setOptionSelected(state, value) {
      state.data.optionSelected = value;
    },
    setDrawdownStrategy(state, value) {
      state.data.drawdownStrategy = value;
    },
    setTotalFundValue(state, value) {
      state.data.totalFundValue = value;
    },
    setTaxFreeLumpSum(state, value) {
      state.data.taxFreeLumpSum = value;
    },
    setGrossAnnualIncome(state, value) {
      state.data.grossAnnualIncome = value;
    },
    setMonthlyIncome(state, value) {
      state.data.monthlyIncome = value;
    },
    setTaxFreeElement(state, value) {
      state.data.taxFreeElement = value;
    },
    setEstimatedFundValueAt75(state, value) {
      state.data.estimatedFundValueAt75 = value;
    },

    setTwentyFivePercentTaxFreeCash(state, value) {
      state.data.twentyFivePercentTaxFreeCash = value;
    },
    setTaxableAmount(state, value) {
      state.data.totalTaxableAmount = value;
    },
    setTaxDueOn20Percent(state, value) {
      state.data.taxDueOn20Percent = value;
    },
    setTaxAt20Percent(state, value) {
      state.data.taxAt20Percent = value;
    },
    setTaxDueOn40Percent(state, value) {
      state.data.taxDueOn40Percent = value;
    },
    setTaxAt40Percent(state, value) {
      state.data.taxAt40Percent = value;
    },
    setTaxDueOn45Percent(state, value) {
      state.data.taxDueOn45Percent = value;
    },
    setTaxAt45Percent(state, value) {
      state.data.taxAt45Percent = value;
    },
    setNetPayment(state, value) {
      state.data.netPayment = value;
    },

    setDateOfBirth(state, value) {
      state.data.dateOfBirth = value;
    },
    setTitle(state, value) {
      state.step1.title = value;
    },
    setForename(state, value) {
      state.step1.forename = value;
    },
    setSurname(state, value) {
      state.step1.surname = value;
    },
    setNINumber(state, value) {
      state.step1.niNumber = value;
    },
    setMaritalStatus(state, value) {
      state.step1.maritalStatus = value;
    },
    setEmail(state, value) {
      state.step1.email = value;
    },
    setTelephoneNumber(state, value) {
      state.step2.telephoneNumber = value;
    },
    setAddressLine1(state, value) {
      state.step2.address.line1 = value;
    },
    setAddressLine2(state, value) {
      state.step2.address.line2 = value;
    },
    setAddressCity(state, value) {
      state.step2.address.city = value;
    },
    setAddressCounty(state, value) {
      state.step2.address.county = value;
    },
    setAddressCountry(state, value) {
      state.step2.address.country = value;
    },
    setAddressPostCode(state, value) {
      state.step2.address.postCode = value;
    },
    // setSchemeName(state, value) {
    //   state.step3.schemeName = value;
    // },
    // setPolicyNumber(state, value) {
    //   state.step3.policyNumber = value;
    // },
    setAllFundsInvestedWithEvolve(state, value) {
      state.step3.allFundsInvestedWithEvolve = value;
    },
    setSchemes(state, value) {
      state.step3.schemes = value;
    },
    // setSchemeAdministrator(state, value) {
    //   state.step3.schemeAdministrator = value;
    // },
    // setSchemeAdminstratorAddressLine1(state, value) {
    //   state.step3.schemeAdministratorAddress.line1 = value;
    // },
    // setSchemeAdminstratorAddressLine2(state, value) {
    //   state.step3.schemeAdministratorAddress.line2 = value;
    // },
    // setSchemeAdminstratorAddressCity(state, value) {
    //   state.step3.schemeAdministratorAddress.city = value;
    // },
    // setSchemeAdminstratorAddressCounty(state, value) {
    //   state.step3.schemeAdministratorAddress.county = value;
    // },
    // setSchemeAdminstratorAddressPostCode(state, value) {
    //   state.step3.schemeAdministratorAddress.postCode = value;
    // },
    // setSchemeAdminstratorAddressCountry(state, value) {
    //   state.step3.schemeAdministratorAddress.country = value;
    // },
    setAccountNumber(state, value) {
      state.step4.accountNumber = value;
    },
    setSortCode(state, value) {
      state.step4.sortCode = value;
    },
    setRollNumber(state, value) {
      state.step4.rollNumber = value;
    },
    setAccountHolderName(state, value) {
      state.step4.accountHolderName = value;
    },
    setBenefitsExceedLifetimeAllowance(state, value) {
      state.step5.benefitsExceedLifetimeAllowance = value;
    },
    setInReceiptOfBenefitsBefore2006(state, value) {
      state.step5.inReceiptOfBenefitsBefore2006 = value;
    },
    setInReceiptOfBenefitsBefore2006SchemeName(state, value) {
      state.step5.inReceiptOfBenefitsBefore2006Data.schemeName = value;
    },
    setInReceiptOfBenefitsBefore2006CurrentAnnualIncome(state, value) {
      state.step5.inReceiptOfBenefitsBefore2006Data.currentAnnualIncome = value;
    },
    setInReceiptOfBenefitsBefore2006DateBenefitCommenced(state, value) {
      state.step5.inReceiptOfBenefitsBefore2006Data.dateBenefitCommenced =
        value;
    },
    setInReceiptOfBenefitsAfter2006(state, value) {
      state.step5.inReceiptOfBenefitsAfter2006 = value;
    },
    setInReceiptOfBenefitsAfter2006SchemeName(state, value) {
      state.step5.inReceiptOfBenefitsAfter2006Data.schemeName = value;
    },
    setInReceiptOfBenefitsAfter2006DateCommenced(state, value) {
      state.step5.inReceiptOfBenefitsAfter2006Data.dateCommenced = value;
    },
    setInReceiptOfBenefitsAfter2006LTAPercentage(state, value) {
      state.step5.inReceiptOfBenefitsAfter2006Data.ltaPercentage = value;
    },
    setRegisteredForProtectionOrEnhancementToLifetimeAllowance(state, value) {
      state.step5.registeredForProtectionOrEnhancementToLifetimeAllowance =
        value;
    },
    setPrimaryProtection(state, value) {
      state.step5.primaryProtection = value;
    },
    setFixedProtection(state, value) {
      state.step5.fixedProtection = value;
    },
    setEnhancedProtection(state, value) {
      state.step5.enhancedProtection = value;
    },
    setLifetimeAllowanceEnhancementFactors(state, value) {
      state.step5.lifetimeAllowanceEnhancementFactors = value;
    },
    setNominees(state, value) {
      state.step6.nominees = value;
    },
    setAdminUID(state, value) {
      state.admin.uid = value;
    },
    setApplicationData(state, value) {
      state.data = value.data;
      state.step1 = value.step1;
      state.step2 = value.step2;
      state.step3 = value.step3;
      state.step4 = value.step4;
      state.step5 = value.step5;
      state.step6 = value.step6;
    },
    clearApplicationData(state) {
      state.data = defaultState().data;
      state.step1 = defaultState().step1;
      state.step2 = defaultState().step2;
      state.step3 = defaultState().step3;
      state.step4 = defaultState().step4;
      state.step5 = defaultState().step5;
      state.step6 = defaultState().step6;
    },
  },
  getters: {
    applicationData(state) {
      const { data, step1, step2, step3, step4, step5, step6 } = state;

      return { data, step1, step2, step3, step4, step5, step6 };
    },
    isLoggedIn(state) {
      return state.admin.uid ? true : false;
    },
    numberOfSteps(state) {
      if (state.data.totalFundValue < 10000) {
        switch (state.data.optionSelected) {
          case RetirementDecision.Annuity:
          case RetirementDecision.UncrystalisedLumpSum:
          case RetirementDecision.LumpSum:
            return 4;
          case RetirementDecision.Drawdown:
            return 5;
        }
      } else {
        switch (state.data.optionSelected) {
          case RetirementDecision.Annuity:
          case RetirementDecision.UncrystalisedLumpSum:
          case RetirementDecision.LumpSum:
            return 5;
          case RetirementDecision.Drawdown:
            return 6;
        }
      }
    },
  },
  actions: {},
  modules: {},
});
