
import { computed, defineComponent, onMounted } from "vue";
import store from "@/store/";
import router, { Routes } from "@/router";

export default defineComponent({
  name: "Step 2",
  components: {},
  setup() {
    onMounted(() => {
      attachLoqate();
    });
    const attachLoqate = () => {
      const pca: any = (window as any).pca;

      const fields = [
        { element: "address-line1", field: "Line1" },
        { element: "address-line2", field: "Line2" },
        { element: "city", field: "City" },
        { element: "county", field: "Province" },
        { element: "postcode", field: "PostalCode" },
        { element: "country", field: "CountryName" },
      ];

      const control = new pca.Address(fields, {
        key: store.state.control.loqate,
        suppressAutocomplete: false,
      });

      control.listen("populate", (address: any) => {
        store.commit("setAddressLine1", address.FormattedLine1);
        store.commit("setAddressLine2", address.FormattedLine2);
        store.commit("setAddressCity", address.City);
        store.commit("setAddressCounty", address.Province);
        store.commit("setAddressPostCode", address.PostalCode);
        store.commit("setAddressCountry", address.CountryName);
      });
    };
    const back = () => {
      router.go(-1);
      // router.push({ name: Routes.Step1 });
    };
    const next = () => {
      router.push({ name: Routes.Step3 });
    };
    const getStepNumber = computed(() => {
      return 2;
    });

    return { back, next, getters: store.getters, getStepNumber };
  },
  computed: {
    telephoneNumber: {
      get() {
        return store.state.step2.telephoneNumber;
      },
      set(value) {
        store.commit("setTelephoneNumber", value);
      },
    },
    line1: {
      get() {
        return store.state.step2.address.line1;
      },
      set(value) {
        store.commit("setAddressLine1", value);
      },
    },
    line2: {
      get() {
        return store.state.step2.address.line2;
      },
      set(value) {
        store.commit("setAddressLine2", value);
      },
    },
    city: {
      get() {
        return store.state.step2.address.city;
      },
      set(value) {
        store.commit("setAddressCity", value);
      },
    },
    county: {
      get() {
        return store.state.step2.address.county;
      },
      set(value) {
        store.commit("setAddressCounty", value);
      },
    },
    postcode: {
      get() {
        return store.state.step2.address.postCode;
      },
      set(value) {
        store.commit("setAddressPostCode", value);
      },
    },
    country: {
      get() {
        return store.state.step2.address.country;
      },
      set(value) {
        store.commit("setAddressCountry", value);
      },
    },
  },
});
