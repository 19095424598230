
import { defineComponent, onMounted, ref } from "vue";
import store from "@/store/";
import { useRoute } from "vue-router";
import router, { Routes } from "@/router";
import axios from "axios";
import filters from "@/filters";

export default defineComponent({
  name: "Application",
  components: {},
  setup() {
    const route = useRoute();
    const applicationReference = ref<string>(route.params.reference as string);

    const back = () => {
      router.push({
        name: Routes.Applications,
      });
    };

    onMounted(() => {
      store.commit("clearApplicationData");

      // Generate some random applications for testing.
      axios
        .post(process.env.VUE_APP_EVOLVE_API_URL + "getCANApplication", {
          idToken: store.state.admin.uid,
          reference: applicationReference.value,
        })
        .then((response) => {
          if (response.data) {
            store.commit("setApplicationData", response.data);
          }

          console.log(response);
          // applications.value = response.data;
          // result = response.data;
        });

      // Get application information.
    });

    return { applicationReference, state: store.state, filters, back };
  },
});
