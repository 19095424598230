<template>
  <h2 class="header">
    Step {{ getStepNumber }} of {{ getters.numberOfSteps }}: Lifetime Allowance
    Information
  </h2>

  <p>
    This section is to help us check your benefits against the lifetime
    allowance (LTA).
  </p>
  <p>
    If your total pension savings (excluding state scheme pensions and spouse’s
    or dependant’s pensions) are greater than the Lifetime Allowance you may
    incur additional tax charges.
  </p>
  <p>The Standard Lifetime allowance for 2021/22 is £1,073,100.</p>

  <div class="my-8 accordion-group">
    <accordion>
      <template v-slot:header>What is Lifetime Allowance (LTA)?</template>
      <template v-slot:content>
        <ul>
          <li>
            The LTA was introduced further to pension’s simplification on 6
            April 2006 and is the total allowable value of tax-privileged
            pensions.
          </li>
          <li>
            If the value of your total pension benefits (excluding state scheme
            pensions) exceeds the LTA you will have to pay additional tax,
            called a recovery charge.
          </li>
          <li>
            Most members will be unaffected because in order for a recovery
            charge to be payable your total pension benefits must exceed
            £1,073,100 for April 2021/22 tax year (This figure does not take
            into account other benefits which you may have built up in other
            pension arrangements).
          </li>
          <li>
            When you complete the Lifetime Allowance Declaration below you must
            notify us of any other pension benefits that you may have become
            entitled to from other sources (excluding state scheme pensions and
            spouse’s or dependant’s pensions).
          </li>
        </ul>
      </template>
    </accordion>

    <accordion>
      <template v-slot:header>Using your tax free lump sum</template>
      <template v-slot:content>
        <ul>
          <li>
            An extra tax charge may also arise if a tax free lump sum from
            Crystal is used to make tax advantaged contributions to another
            pension scheme.
          </li>
          <li>
            If you are thinking of making an extra contribution to another
            pension scheme after receiving benefits from Crystal, please contact
            us so that we may discuss this with you.
          </li>
        </ul>
      </template>
    </accordion>

    <accordion>
      <template v-slot:header
        >What if you have registered for protection with HM Revenue &
        Customs?</template
      >
      <template v-slot:content>
        <ul>
          <li>
            If you were entitled to (or prospectively entitled to) benefits
            above the LTA as at 5 April 2006, you may have applied for
            protection against the LTA recovery charge. This is known as
            "primary protection” and "enhanced protection". Alternatively since
            6 April 2012 you may have applied for “Fixed Protection”.
          </li>
          <li>
            If this applies to you, the details of the protection will be set
            out in a certificate sent to you by HM Revenue & Customs.
          </li>
          <li>
            You will need to send a copy of your registration certificate to us
            before completing the Lifetime Allowance Declaration. We will then
            provide you with further details about how this affects the LTA
            recovery charge.
          </li>
        </ul>
      </template>
    </accordion>

    <accordion>
      <template v-slot:header
        >Must any other special circumstances be notified to Crystal?</template
      >
      <template v-slot:content>
        <p>Yes. Please ensure you notify Crystal Administration if you have:</p>
        <ul>
          <li>been awarded a pension credit on divorce;</li>
          <li>transferred benefits from an overseas pension scheme; or</li>
          <li>
            not received tax relief on your contributions to any UK registered
            pension scheme because you have not been resident in the UK.
          </li>
        </ul>
        <p>
          These circumstances could increase the tax efficient benefits
          available to you.
        </p>
      </template>
    </accordion>
  </div>

  <form @submit.prevent="next()">
    <div class="input-wrapper required">
      <div class="flex justify-between">
        <label for="benefits-exceed-lifetime-allowance" class="cell shrink"
          >Do your benefits exceed the Lifetime Allowance (LTA)?</label
        >
        <tooltip
          content="If you select yes, we will require additional information to process your application. The Crystal Administration team will contact you.
"
        >
          <i class="fas fa-info-circle"></i>
        </tooltip>
      </div>

      <select
        id="benefits-exceed-lifetime-allowance"
        class="input"
        v-model="benefitsExceedLifetimeAllowance"
        required
      >
        <option disabled selected value="">Select</option>
        <option>Yes</option>
        <option>No</option>
      </select>
    </div>

    <h3 class="sub-header">
      Pension Benefits in payment before 6th April 2006.
    </h3>

    <div class="input-wrapper required">
      <label for="in-receipt-of-benefits-from-before-2006"
        >Are you in receipt of any pension benefits that started before 6th
        April 2006?
      </label>
      <select
        id="in-receipt-of-benefits-from-before-2006"
        class="input"
        v-model="inReceiptOfBenefitsBefore2006"
        required
      >
        <option disabled selected value="">Select</option>
        <option>Yes</option>
        <option>No</option>
      </select>
    </div>

    <template v-if="inReceiptOfBenefitsBefore2006 === 'Yes'">
      <div class="input-wrapper required">
        <label for="before-2006-scheme-name">Scheme Name</label>
        <input
          id="before-2006-scheme-name"
          class="input"
          type="text"
          required
          v-model="benefitsBefore2006SchemeName"
        />
      </div>

      <div class="input-wrapper required">
        <label for="before-2006-annual-income">Current Annual income</label>
        <vs-input
          id="before-2006-annual-income"
          type="number"
          filter="currency"
          :value="benefitsBefore2006CurrentAnnualIncome"
          @change="benefitsBefore2006CurrentAnnualIncome = $event"
        />
      </div>

      <div class="input-wrapper required">
        <label for="before-2006-date-benefit-commenced"
          >Date Benefit Commenced</label
        >
        <input
          id="before-2006-date-benefit-commenced"
          class="input"
          type="date"
          required
          v-model="benefitsBefore2006DataBenefitCommenced"
        />
      </div>
    </template>

    <h3 class="sub-header">
      Pension Benefits in payment after 5th April 2006.
    </h3>

    <div class="input-wrapper required">
      <label for="in-receipt-of-benefits-from-after-2006"
        >Are you in receipt of any pensions that started after 5th April 2006?
      </label>
      <select
        id="in-receipt-of-benefits-from-after-2006"
        class="input"
        v-model="inReceiptOfBenefitsAfter2006"
        required
      >
        <option disabled selected value="">Select</option>
        <option>Yes</option>
        <option>No</option>
      </select>
    </div>

    <template v-if="inReceiptOfBenefitsAfter2006 === 'Yes'">
      <div class="input-wrapper">
        <label for="after-2006-scheme-name">Scheme Name</label>
        <input
          id="after-2006-scheme-name"
          class="input"
          type="text"
          required
          v-model="benefitsAfter2006SchemeName"
        />
      </div>

      <div class="input-wrapper">
        <label for="after-2006-lta-percentage">LTA Percentage</label>
        <vs-input
          id="after-2006-lta-percentage"
          type="number"
          filter="percentage"
          :min="10"
          :max="99"
          :value="benefitsAfter2006LTAPercentage"
          @change="benefitsAfter2006LTAPercentage = $event"
        />
      </div>

      <div class="input-wrapper">
        <label for="after-2006-date-benefit-commenced"
          >Date Benefit Commenced</label
        >
        <input
          id="after-2006-date-benefit-commenced"
          class="input"
          type="date"
          required
          v-model="benefitsAfter2006DateCommenced"
        />
      </div>
    </template>

    <div class="input-wrapper required">
      <div class="flex justify-between">
        <label
          for="registered-for-protection-or-enhancement"
          class="cell shrink"
          >Have you registered with HM Revenue & Customs for any protection or
          enhancement to your Lifetime Allowance?</label
        >
        <tooltip
          content="If you answer yes to any of the following questions please send us a copy of the HM Revenue & Customs protection certificate."
        >
          <i class="fas fa-info-circle"></i>
        </tooltip>
      </div>

      <select
        id="registered-for-protection-or-enhancement"
        class="input"
        v-model="registeredForProtectionOrEnhancementToLifetimeAllowance"
        required
      >
        <option disabled selected value="">Select</option>
        <option>Yes</option>
        <option>No</option>
      </select>
    </div>

    <div class="input-wrapper required">
      <label for="primary-protection">Primary Protection </label>
      <select
        id="primary-protection"
        class="input"
        v-model="primaryProtection"
        required
      >
        <option disabled selected value="">Select</option>
        <option>Yes</option>
        <option>No</option>
      </select>
    </div>

    <div class="input-wrapper required">
      <label for="fixed-protection">Fixed Protection </label>
      <select
        id="fixed-protection"
        class="input"
        v-model="fixedProtection"
        required
      >
        <option disabled selected value="">Select</option>
        <option>Yes</option>
        <option>No</option>
      </select>
    </div>

    <div class="input-wrapper required">
      <label for="enhanced-protection">Enhanced Protection </label>
      <select
        id="enhanced-protection"
        class="input"
        v-model="enhancedProtection"
        required
      >
        <option disabled selected value="">Select</option>
        <option>Yes</option>
        <option>No</option>
      </select>
    </div>

    <div class="input-wrapper required">
      <label for="lifetime-allowance-enhancement-factors"
        >Lifetime Allowance Enhancement factors
      </label>
      <select
        id="lifetime-allowance-enhancement-factors"
        class="input"
        v-model="lifetimeAllowanceEnhancementFactors"
        required
      >
        <option disabled selected value="">Select</option>
        <option>Yes</option>
        <option>No</option>
      </select>
    </div>

    <template v-if="lifetimeAllowanceEnhancementFactors === 'Yes'">
      <p class="flex gap-4 my-2 text-bold warning">
        <i class="fas fa-exclamation-triangle"></i>
        Please send Crystal Administration a copy of the HM Revenue & Customs
        protection certificates
      </p>
    </template>

    <div class="sub-header">Important Notes</div>
    <p class="mb-4">
      If you make a false statement or representation when completing the
      Lifetime Allowance Declaration, you may become liable to a penalty in
      certain circumstances including liability for a tax charge. If you do not
      notify Crystal that you have applied for "primary protection”, "enhanced
      protection" or “fixed protection” the Trustees will assume that no such
      protection has been or will be applied for. If you do not notify Crystal
      that any of the special circumstances referred to above apply the Trustee
      will assume that they do not.
    </p>

    <div class="button-group">
      <button type="button" class="button" @click="back()">
        <i class="fas fa-arrow-left"></i> Back
      </button>
      <button type="submit" class="button">
        Next <i class="fas fa-arrow-right"></i>
      </button>
    </div>
  </form>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import store, { RetirementDecision } from "@/store/";
import router, { Routes } from "@/router";

export default defineComponent({
  name: "Step 5",
  components: {},
  setup() {
    const back = () => {
      router.go(-1);
      // router.push({ name: Routes.Step4 });
    };
    const next = () => {
      if (store.state.data.optionSelected === RetirementDecision.Drawdown) {
        router.push({ name: Routes.Step6 });
      } else {
        router.push({ name: Routes.Submit });
      }
    };
    const getStepNumber = computed(() => {
      return 5;
    });
    return {
      back,
      next,
      state: store.state,
      getters: store.getters,
      getStepNumber,
    };
  },
  computed: {
    benefitsExceedLifetimeAllowance: {
      get() {
        return store.state.step5.benefitsExceedLifetimeAllowance;
      },
      set(value) {
        store.commit("setBenefitsExceedLifetimeAllowance", value);
      },
    },
    inReceiptOfBenefitsBefore2006: {
      get() {
        return store.state.step5.inReceiptOfBenefitsBefore2006;
      },
      set(value) {
        store.commit("setInReceiptOfBenefitsBefore2006", value);
      },
    },
    benefitsBefore2006SchemeName: {
      get() {
        return store.state.step5.inReceiptOfBenefitsBefore2006Data.schemeName;
      },
      set(value) {
        store.commit("setInReceiptOfBenefitsBefore2006SchemeName", value);
      },
    },
    benefitsBefore2006CurrentAnnualIncome: {
      get() {
        return store.state.step5.inReceiptOfBenefitsBefore2006Data
          .currentAnnualIncome;
      },
      set(value) {
        store.commit(
          "setInReceiptOfBenefitsBefore2006CurrentAnnualIncome",
          value
        );
      },
    },
    benefitsBefore2006DataBenefitCommenced: {
      get() {
        return store.state.step5.inReceiptOfBenefitsBefore2006Data
          .dateBenefitCommenced;
      },
      set(value) {
        store.commit(
          "setInReceiptOfBenefitsBefore2006DateBenefitCommenced",
          value
        );
      },
    },
    inReceiptOfBenefitsAfter2006: {
      get() {
        return store.state.step5.inReceiptOfBenefitsAfter2006;
      },
      set(value) {
        store.commit("setInReceiptOfBenefitsAfter2006", value);
      },
    },
    benefitsAfter2006SchemeName: {
      get() {
        return store.state.step5.inReceiptOfBenefitsAfter2006Data.schemeName;
      },
      set(value) {
        store.commit("setInReceiptOfBenefitsAfter2006SchemeName", value);
      },
    },
    benefitsAfter2006DateCommenced: {
      get() {
        return store.state.step5.inReceiptOfBenefitsAfter2006Data.dateCommenced;
      },
      set(value) {
        store.commit("setInReceiptOfBenefitsAfter2006DateCommenced", value);
      },
    },
    benefitsAfter2006LTAPercentage: {
      get() {
        return store.state.step5.inReceiptOfBenefitsAfter2006Data.ltaPercentage;
      },
      set(value) {
        store.commit("setInReceiptOfBenefitsAfter2006LTAPercentage", value);
      },
    },
    registeredForProtectionOrEnhancementToLifetimeAllowance: {
      get() {
        return store.state.step5
          .registeredForProtectionOrEnhancementToLifetimeAllowance;
      },
      set(value) {
        store.commit(
          "setRegisteredForProtectionOrEnhancementToLifetimeAllowance",
          value
        );
      },
    },
    primaryProtection: {
      get() {
        return store.state.step5.primaryProtection;
      },
      set(value) {
        store.commit("setPrimaryProtection", value);
      },
    },
    fixedProtection: {
      get() {
        return store.state.step5.fixedProtection;
      },
      set(value) {
        store.commit("setFixedProtection", value);
      },
    },
    enhancedProtection: {
      get() {
        return store.state.step5.enhancedProtection;
      },
      set(value) {
        store.commit("setEnhancedProtection", value);
      },
    },
    lifetimeAllowanceEnhancementFactors: {
      get() {
        return store.state.step5.lifetimeAllowanceEnhancementFactors;
      },
      set(value) {
        store.commit("setLifetimeAllowanceEnhancementFactors", value);
      },
    },
  },
});
</script>
