import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Step1 from "../pages/Step1.vue";
import Step2 from "../pages/Step2.vue";
import Step3 from "../pages/Step3.vue";
import Step4 from "../pages/Step4.vue";
import Step5 from "../pages/Step5.vue";
import Step6 from "../pages/Step6.vue";
import Submit from "../pages/Submit.vue";

import Admin from "../pages/Admin/SignIn.vue";
import Applications from "../pages/Admin/Applications.vue";
import Application from "../pages/Admin/Application.vue";

export enum Routes {
  Step1 = "Step-1",
  Step2 = "Step-2",
  Step3 = "Step-3",
  Step4 = "Step-4",
  Step5 = "Step-5",
  Step6 = "Step-6",
  Submit = "Submit",
  Admin = "Admin",
  Applications = "Applications",
  Application = "Application",
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: Routes.Step1,
    component: Step1,
  },
  {
    path: "/step-2",
    name: Routes.Step2,
    component: Step2,
  },
  {
    path: "/step-3",
    name: Routes.Step3,
    component: Step3,
  },
  {
    path: "/step-4",
    name: Routes.Step4,
    component: Step4,
  },
  {
    path: "/step-5",
    name: Routes.Step5,
    component: Step5,
  },
  {
    path: "/step-6",
    name: Routes.Step6,
    component: Step6,
  },
  {
    path: "/submit",
    name: Routes.Submit,
    component: Submit,
  },
  {
    path: "/admin",
    name: Routes.Admin,
    component: Admin,
  },
  {
    path: "/admin/applications",
    name: Routes.Applications,
    component: Applications,
  },
  {
    path: "/admin/application/:reference",
    name: Routes.Application,
    component: Application,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
