<template>
  <div class="accordion" :class="{ active: contentActive }">
    <div
      ref="headerRef"
      class="flex justify-between w-full cursor-pointer header"
      @click="onClick()"
    >
      <slot name="header" />

      <div v-show="contentActive">
        <i class="fas fa-minus"></i>
      </div>

      <div v-show="!contentActive" class="text-brand-green">
        <i class="fas fa-plus"></i>
      </div>
    </div>

    <div
      ref="contentRef"
      class="overflow-hidden transition-all duration-300 ease-out content"
      :style="{ 'max-height': `${maxHeight}px` }"
    >
      <div class="py-2 whitespace-pre-line">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
export default defineComponent({
  name: "Accordion",
  setup() {
    const headerRef = ref<HTMLElement | null>(null);
    const contentRef = ref<HTMLElement | null>(null);
    const maxHeight = ref<number>(0);
    const contentActive = ref<boolean>(false);
    const onClick = () => {
      contentActive.value = !contentActive.value;
      if (contentRef.value) {
        maxHeight.value = contentActive.value
          ? contentRef.value.scrollHeight
          : 0;
      }
    };
    return {
      headerRef,
      contentRef,
      contentActive,
      maxHeight,
      onClick,
    };
  },
});
</script>
