
import { computed, defineComponent, ref, onBeforeUnmount, nextTick } from "vue";
import store, { Scheme } from "@/store/";
import router, { Routes } from "@/router";
import filters from "@/filters";

export default defineComponent({
  name: "Step 3",
  components: {},
  setup() {
    const error = ref<string>("");
    const schemes = ref<Scheme[]>(store.state.step3.schemes);

    onBeforeUnmount(() => {
      store.commit("setSchemes", schemes.value);
    });

    const addScheme = () => {
      if (schemes.value.length < 10) {
        schemes.value.push(new Scheme());

        nextTick(() => {
          const pca: any = (window as any).pca;
          const schemeValue = schemes.value.length - 1;

          const fields = [
            {
              element: `schemeAddressLine1-${schemeValue}`,
              field: "Line1",
            },
            {
              element: `schemeAddressLine2-${schemeValue}`,
              field: "Line2",
            },
            {
              element: `schemeAddressCity-${schemeValue}`,
              field: "City",
            },
            {
              element: `schemeAddressCounty-${schemeValue}`,
              field: "Province",
            },
            {
              element: `schemeAddressPostcode-${schemeValue}`,
              field: "PostalCode",
            },
            {
              element: `schemeAddressCountry-${schemeValue}`,
              field: "CountryName",
            },
          ];

          const control = new pca.Address(fields, {
            key: store.state.control.loqate,
            suppressAutocomplete: false,
          });

          control.listen("populate", (address: any) => {
            schemes.value[schemeValue].addressLine1 = address.FormattedLine1;
            schemes.value[schemeValue].addressLine2 = address.FormattedLine2;
            schemes.value[schemeValue].addressCity = address.City;
            schemes.value[schemeValue].addressCounty = address.Province;
            schemes.value[schemeValue].addressPostCode = address.PostalCode;
            schemes.value[schemeValue].addressCountry = address.CountryName;
          });

          var fundingSource = document.getElementById(
            `scheme-${schemes.value.length - 1}`
          );
          fundingSource?.scrollIntoView();
        });

        error.value = "";
      } else {
        error.value = "You already have the maximum number of schemes (10)";
      }
    };

    const removeScheme = (index: number) => {
      schemes.value.splice(index, 1);
      error.value = "";
    };

    const back = () => {
      router.go(-1);
    };

    const next = () => {
      var canGoNext = false;

      if (store.state.step3.allFundsInvestedWithEvolve === "Yes") {
        canGoNext = true;
      } else if (
        store.state.step3.allFundsInvestedWithEvolve === "No" &&
        schemes.value.length > 0
      ) {
        canGoNext = true;
      } else {
        error.value = "Please list your fund sources.";
      }

      if (canGoNext) {
        router.push({ name: Routes.Step4 });
      }
    };

    const getStepNumber = computed(() => {
      return 3;
    });

    return {
      back,
      next,
      filters,
      state: store.state,
      getters: store.getters,
      getStepNumber,
      addScheme,
      removeScheme,
      schemes,
      error,
    };
  },
  computed: {
    allFundsInvestedWithEvolve: {
      get() {
        return store.state.step3.allFundsInvestedWithEvolve;
      },
      set(value) {
        store.commit("setAllFundsInvestedWithEvolve", value);
      },
    },
    schemeName: {
      get() {
        return store.state.step3.schemeName;
      },
      set(value) {
        store.commit("setSchemeName", value);
      },
    },
    policyNumber: {
      get() {
        return store.state.step3.policyNumber;
      },
      set(value) {
        store.commit("setPolicyNumber", value);
      },
    },
    schemeAdministrator: {
      get() {
        return store.state.step3.schemeAdministrator;
      },
      set(value) {
        store.commit("setSchemeAdministrator", value);
      },
    },
    line1: {
      get() {
        return store.state.step3.schemeAdministratorAddress.line1;
      },
      set(value) {
        store.commit("setSchemeAdminstratorAddressLine1", value);
      },
    },
    line2: {
      get() {
        return store.state.step3.schemeAdministratorAddress.line2;
      },
      set(value) {
        store.commit("setSchemeAdminstratorAddressLine2", value);
      },
    },
    city: {
      get() {
        return store.state.step3.schemeAdministratorAddress.city;
      },
      set(value) {
        store.commit("setSchemeAdminstratorAddressCity", value);
      },
    },
    county: {
      get() {
        return store.state.step3.schemeAdministratorAddress.county;
      },
      set(value) {
        store.commit("setSchemeAdminstratorAddressCounty", value);
      },
    },
    postCode: {
      get() {
        return store.state.step3.schemeAdministratorAddress.postCode;
      },
      set(value) {
        store.commit("setSchemeAdminstratorAddressPostCode", value);
      },
    },
    country: {
      get() {
        return store.state.step3.schemeAdministratorAddress.country;
      },
      set(value) {
        store.commit("setSchemeAdminstratorAddressCountry", value);
      },
    },
  },
});
