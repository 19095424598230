
import { defineComponent, onMounted, ref } from "vue";
import store, { DrawdownStrategy, RetirementDecision } from "@/store/";

export default defineComponent({
  name: "App",
  components: {},
  setup() {
    const iFramed = ref<boolean>(false);

    onMounted(() => {
      console.log("mounted");

      iFramed.value = window.location !== window.parent.location;

      window.addEventListener("message", (event) => {
        if (
          typeof event.data === "object" &&
          event.data.call === "sendInputs"
        ) {
          for (const [key, value] of Object.entries(event.data)) {
            switch (key) {
              case "optionSelected":
                store.commit("setOptionSelected", value);
                break;
              case "drawdownStrategy":
                store.commit("setDrawdownStrategy", value);
                break;
              case "totalFundValue":
                store.commit("setTotalFundValue", value);
                break;
              case "taxFreeLumpSum":
                store.commit("setTaxFreeLumpSum", value);
                break;
              case "grossAnnualIncome":
                store.commit("setGrossAnnualIncome", value);
                break;
              case "monthlyIncome":
                store.commit("setMonthlyIncome", value);
                break;
              case "taxFreeElement":
                store.commit("setTaxFreeElement", value);
                break;
              case "estimatedFundValueAt75":
                store.commit("setEstimatedFundValueAt75", value);
                break;
              case "twentyFivePercentTaxFreeCash":
                store.commit("setTwentyFivePercentTaxFreeCash", value);
                break;
              case "totalTaxableAmount":
                store.commit("setTaxableAmount", value);
                break;
              case "taxAt20Percent":
                store.commit("setTaxAt20Percent", value);
                break;
              case "taxAt40Percent":
                store.commit("setTaxAt40Percent", value);
                break;
              case "taxAt45Percent":
                store.commit("setTaxAt45Percent", value);
                break;
              case "taxDueOn20Percent":
                store.commit("setTaxDueOn20Percent", value);
                break;
              case "taxDueOn40Percent":
                store.commit("setTaxDueOn40Percent", value);
                break;
              case "taxDueOn45Percent":
                store.commit("setTaxDueOn45Percent", value);
                break;
              case "netPayment":
                store.commit("setNetPayment", value);
                break;
              case "dateOfBirth":
                store.commit("setDateOfBirth", value);
                break;
              case "title":
                store.commit("setTitle", value);
                break;
              case "forename":
                store.commit("setForename", value);
                break;
              case "surname":
                store.commit("setSurname", value);
                break;
              case "niNumber":
                store.commit("setNINumber", value);
                break;
              case "email":
                store.commit("setEmail", value);
                break;
              case "telephoneNumber":
                store.commit("setTelephoneNumber", value);
                break;
              case "line1":
                store.commit("setAddressLine1", value);
                break;
              case "line2":
                store.commit("setAddressLine2", value);
                break;
              case "city":
                store.commit("setAddressCity", value);
                break;
              case "county":
                store.commit("setAddressCounty", value);
                break;
              case "country":
                store.commit("setAddressCountry", value);
                break;
              case "postCode":
                store.commit("setAddressPostCode", value);
                break;
            }
          }
        }
      });

      // This doesn't work because technically it is dev on staging.
      if (store.state.isTest) {
        // Generic.
        /*window.postMessage(
          {
            call: "sendInputs",
            optionSelected: RetirementDecision.LumpSum,
            drawdownStrategy: DrawdownStrategy.Aqua,
            totalFundValue: 50000.0,
            taxFreeLumpSum: 0.0,
            grossAnnualIncome: 1500.0,
            monthlyIncome: 125.0,
            taxFreeElement: 31.0,
            estimatedFundValueAt75: 31265.0,
            dateOfBirth: "09/05/1965",
            forename: "test123",
            title: "Mx",
          },
          "*"
        );*/
        // Lump sum
        /* window.postMessage(
          {
            call: "sendInputs",
            optionSelected: RetirementDecision.LumpSum,
            totalFundValue: 50000.0,
            twentyFivePercentTaxFreeCash: 1,
            taxAt20Percent: 2,
            netPayment: 3,
            dateOfBirth: "09/05/1965",
            forename: "test123",
          },
          "*"
        ); */
        // Lump sum
        /*window.postMessage(
          {
            call: "sendInputs",
            optionSelected: RetirementDecision.UncrystalisedLumpSum,
            totalFundValue: 50000.0,
            twentyFivePercentTaxFreeCash: 1,
            totalTaxableAmount: 2,

            taxDueOn20Percent: 3,
            taxDueOn40Percent: 4,
            taxDueOn45Percent: 5,

            taxAt20Percent: 6,
            taxAt40Percent: 7,
            taxAt45Percent: 8,

            dateOfBirth: "09/05/1965",
            forename: "test123",
          },
          "*"
        );*/
      }
    });

    return {
      iFramed,
    };
  },
});
