<template>
  <h2 class="header">
    Step {{ getStepNumber }} of {{ getters.numberOfSteps }}: Fund you wish to
    transfer to Crystal
  </h2>

  <form @submit.prevent="next()">
    <div class="input-wrapper">
      <div class="flex justify-between">
        <label for="value-of-fund" class="cell shrink">Total fund value</label>
        <tooltip
          content="This is the current value of your pension fund. If you need to correct this information, please return to evoRetire."
        >
          <i class="fas fa-info-circle"></i>
        </tooltip>
      </div>

      <div class="input">
        {{ filters.currency(state.data.totalFundValue) }}
      </div>
    </div>

    <div class="input-wrapper">
      <div class="flex justify-between">
        <label for="all-invested-in-evolve" class="cell shrink"
          >Is all of this fund currently invested in Crystal?
        </label>
      </div>

      <select
        id="all-invested-in-evolve"
        class="input"
        required
        v-model="allFundsInvestedWithEvolve"
      >
        <option disabled selected value="">Select</option>
        <option>Yes</option>
        <option>No</option>
      </select>
    </div>

    <template v-if="allFundsInvestedWithEvolve === 'No'">
      <h2 class="sub-header">
        Please input the details for each of the funds you wish to transfer into
        Crystal.
      </h2>

      <div>
        <button type="button" @click="addScheme()" class="mb-4">
          <i class="fas fa-plus-circle"></i> Add a funding source
        </button>
      </div>

      <div
        :id="`scheme-${index}`"
        v-for="(scheme, index) in schemes"
        :key="index"
      >
        <div class="flex items-center justify-center my-4">
          <h2 class="flex-grow text-center">Funding Source {{ index + 1 }}</h2>

          <div class="text-lg cursor-pointer" @click="removeScheme(index)">
            <i class="fas fa-minus-circle"></i>
          </div>
        </div>

        <div class="input-wrapper required">
          <label :for="`schemeAdministrator-${index}`"
            >Scheme Administrator</label
          >
          <input
            :id="`schemeAdministrator-${index}`"
            type="text"
            class="input"
            v-model="scheme.schemeAdministrator"
            required
          />
        </div>

        <div class="input-wrapper required">
          <label :for="`schemeName-${index}`">Scheme Name</label>
          <input
            :id="`schemeName-${index}`"
            type="text"
            class="input"
            v-model="scheme.schemeName"
            required
          />
        </div>

        <div class="input-wrapper">
          <label :for="`policyReference-${index}`"
            >Policy/Reference Number</label
          >
          <input
            :id="`policyReference-${index}`"
            type="text"
            class="input"
            v-model="scheme.referenceNumber"
          />
        </div>

        <div class="input-wrapper required">
          <label :for="`schemeFund-${index}`">Fund value</label>
          <vs-input
            :id="`schemeFund-${index}`"
            type="number"
            filter="currency"
            :value="scheme.fundValue"
            @change="scheme.fundValue = $event"
          />
        </div>

        <div class="input-wrapper required">
          <label :for="`schemeAddressLine1-${index}`">Address line 1</label>
          <input
            :id="`schemeAddressLine1-${index}`"
            type="text"
            class="input"
            v-model="scheme.addressLine1"
            required
            autocomplete="none"
          />
        </div>

        <div class="input-wrapper">
          <label :for="`schemeAddressLine2-${index}`">Address line 2</label>
          <input
            :id="`schemeAddressLine2-${index}`"
            type="text"
            class="input"
            v-model="scheme.addressLine2"
            autocomplete="none"
          />
        </div>

        <div class="input-wrapper required">
          <label :for="`schemeAddressCity-${index}`">City</label>
          <input
            :id="`schemeAddressCity-${index}`"
            type="text"
            class="input"
            v-model="scheme.addressCity"
            required
            autocomplete="none"
          />
        </div>

        <div class="input-wrapper">
          <label :for="`schemeAddressCounty-${index}`">County</label>
          <input
            :id="`schemeAddressCounty-${index}`"
            type="text"
            class="input"
            v-model="scheme.addressCounty"
            autocomplete="none"
          />
        </div>

        <div class="input-wrapper required">
          <label :for="`schemeAddressPostcode-${index}`">Postcode</label>
          <input
            :id="`schemeAddressPostcode-${index}`"
            type="text"
            class="input"
            v-model="scheme.addressPostCode"
            required
            autocomplete="none"
            pattern="([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})"
          />
        </div>

        <div class="input-wrapper">
          <label :for="`schemeAddressCountry-${index}`">Country</label>
          <input
            :id="`schemeAddressCountry-${index}`"
            type="text"
            class="input"
            v-model="scheme.addressCountry"
            autocomplete="none"
          />
        </div>
      </div>
    </template>

    <div v-if="error" class="my-4 text-sm">
      <p class="error">{{ error }}</p>
    </div>

    <div class="button-group">
      <button type="button" class="button" @click="back()">
        <i class="fas fa-arrow-left"></i> Back
      </button>
      <button type="submit" class="button">
        Next <i class="fas fa-arrow-right"></i>
      </button>
    </div>
  </form>
</template>

<script lang="ts">
import { computed, defineComponent, ref, onBeforeUnmount, nextTick } from "vue";
import store, { Scheme } from "@/store/";
import router, { Routes } from "@/router";
import filters from "@/filters";

export default defineComponent({
  name: "Step 3",
  components: {},
  setup() {
    const error = ref<string>("");
    const schemes = ref<Scheme[]>(store.state.step3.schemes);

    onBeforeUnmount(() => {
      store.commit("setSchemes", schemes.value);
    });

    const addScheme = () => {
      if (schemes.value.length < 10) {
        schemes.value.push(new Scheme());

        nextTick(() => {
          const pca: any = (window as any).pca;
          const schemeValue = schemes.value.length - 1;

          const fields = [
            {
              element: `schemeAddressLine1-${schemeValue}`,
              field: "Line1",
            },
            {
              element: `schemeAddressLine2-${schemeValue}`,
              field: "Line2",
            },
            {
              element: `schemeAddressCity-${schemeValue}`,
              field: "City",
            },
            {
              element: `schemeAddressCounty-${schemeValue}`,
              field: "Province",
            },
            {
              element: `schemeAddressPostcode-${schemeValue}`,
              field: "PostalCode",
            },
            {
              element: `schemeAddressCountry-${schemeValue}`,
              field: "CountryName",
            },
          ];

          const control = new pca.Address(fields, {
            key: store.state.control.loqate,
            suppressAutocomplete: false,
          });

          control.listen("populate", (address: any) => {
            schemes.value[schemeValue].addressLine1 = address.FormattedLine1;
            schemes.value[schemeValue].addressLine2 = address.FormattedLine2;
            schemes.value[schemeValue].addressCity = address.City;
            schemes.value[schemeValue].addressCounty = address.Province;
            schemes.value[schemeValue].addressPostCode = address.PostalCode;
            schemes.value[schemeValue].addressCountry = address.CountryName;
          });

          var fundingSource = document.getElementById(
            `scheme-${schemes.value.length - 1}`
          );
          fundingSource?.scrollIntoView();
        });

        error.value = "";
      } else {
        error.value = "You already have the maximum number of schemes (10)";
      }
    };

    const removeScheme = (index: number) => {
      schemes.value.splice(index, 1);
      error.value = "";
    };

    const back = () => {
      router.go(-1);
    };

    const next = () => {
      var canGoNext = false;

      if (store.state.step3.allFundsInvestedWithEvolve === "Yes") {
        canGoNext = true;
      } else if (
        store.state.step3.allFundsInvestedWithEvolve === "No" &&
        schemes.value.length > 0
      ) {
        canGoNext = true;
      } else {
        error.value = "Please list your fund sources.";
      }

      if (canGoNext) {
        router.push({ name: Routes.Step4 });
      }
    };

    const getStepNumber = computed(() => {
      return 3;
    });

    return {
      back,
      next,
      filters,
      state: store.state,
      getters: store.getters,
      getStepNumber,
      addScheme,
      removeScheme,
      schemes,
      error,
    };
  },
  computed: {
    allFundsInvestedWithEvolve: {
      get() {
        return store.state.step3.allFundsInvestedWithEvolve;
      },
      set(value) {
        store.commit("setAllFundsInvestedWithEvolve", value);
      },
    },
    schemeName: {
      get() {
        return store.state.step3.schemeName;
      },
      set(value) {
        store.commit("setSchemeName", value);
      },
    },
    policyNumber: {
      get() {
        return store.state.step3.policyNumber;
      },
      set(value) {
        store.commit("setPolicyNumber", value);
      },
    },
    schemeAdministrator: {
      get() {
        return store.state.step3.schemeAdministrator;
      },
      set(value) {
        store.commit("setSchemeAdministrator", value);
      },
    },
    line1: {
      get() {
        return store.state.step3.schemeAdministratorAddress.line1;
      },
      set(value) {
        store.commit("setSchemeAdminstratorAddressLine1", value);
      },
    },
    line2: {
      get() {
        return store.state.step3.schemeAdministratorAddress.line2;
      },
      set(value) {
        store.commit("setSchemeAdminstratorAddressLine2", value);
      },
    },
    city: {
      get() {
        return store.state.step3.schemeAdministratorAddress.city;
      },
      set(value) {
        store.commit("setSchemeAdminstratorAddressCity", value);
      },
    },
    county: {
      get() {
        return store.state.step3.schemeAdministratorAddress.county;
      },
      set(value) {
        store.commit("setSchemeAdminstratorAddressCounty", value);
      },
    },
    postCode: {
      get() {
        return store.state.step3.schemeAdministratorAddress.postCode;
      },
      set(value) {
        store.commit("setSchemeAdminstratorAddressPostCode", value);
      },
    },
    country: {
      get() {
        return store.state.step3.schemeAdministratorAddress.country;
      },
      set(value) {
        store.commit("setSchemeAdminstratorAddressCountry", value);
      },
    },
  },
});
</script>
