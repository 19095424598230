import "@fortawesome/fontawesome-free/js/all";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Accordion from "@/components/Accordion.vue";
import Tooltip from "@/components/Tooltip.vue";
import VSInput from "@/components/VS-Input.vue";

import "./plugins/firebase";

const app = createApp(App);

app.use(store).use(router);

app.component("accordion", Accordion);
app.component("tooltip", Tooltip);
app.component("vs-input", VSInput);

app.mount("#app");
