<template>
  <div ref="eleRef" class="inline-block">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, PropType } from "vue";
import tippy, { Placement } from "tippy.js";

export default defineComponent({
  props: {
    content: {
      type: String,
      required: true,
    },
    interactive: {
      type: Boolean,
      required: false,
      default: false,
    },
    arrow: {
      type: Boolean,
      required: false,
      default: true,
    },
    placement: {
      type: String as PropType<Placement>,
      required: false,
      default: "auto",
    },
  },
  setup(props) {
    const eleRef = ref<HTMLInputElement | null>(null);

    onMounted(() => {
      if (eleRef.value) {
        tippy(eleRef.value, {
          content: props.content,
          allowHTML: true,
          arrow: props.arrow,
          interactive: props.interactive,
          placement: props.placement,
        });
      }
    });

    return {
      eleRef,
    };
  },
});
</script>
