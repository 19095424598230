import accounting from "accounting";
import moment from "moment";

export default {
  currency(val: number): string {
    return accounting.formatMoney(val, "£", 2);
  },
  percentage(val: number): string {
    return `${val}%`;
  },
  date(val: moment.Moment | Date | string): string {
    if (val) {
      return moment(val).format("DD/MM/YYYY");
    } else {
      return "";
    }
  },
};
