<template>
  <!-- numberOfSteps of numberOfSteps because this is always the last page. -->
  <template v-if="!submitting">
    <h2 class="header">Submit application</h2>

    <p class="mb-4">
      If you're happy with your choices, click below to submit your application.
    </p>

    <div class="button-group">
      <button type="button" class="button" @click="back()">
        <i class="fas fa-arrow-left"></i> Back
      </button>
      <button type="submit" class="button" @click="next()">
        Submit application <i class="fas fa-arrow-right"></i>
      </button>
    </div>
  </template>

  <div v-else-if="submitted">
    <h2 class="header">Submitted.</h2>
    <p>
      Thank you for your submission, we'll be in touch in the next 10 working
      days to discuss your application.
    </p>
  </div>
  <div v-else-if="failed">
    <h2 class="header">Failed</h2>
    <p>
      Unfortunately there has been a problem with your submission, please try
      again later.
    </p>
  </div>
  <div v-else>
    <h2 class="header">Submitting...</h2>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import store from "@/store/";
import router, { Routes } from "@/router";
import axios from "axios";

export default defineComponent({
  name: "Step 6",
  components: {},
  setup() {
    const error = ref<string>("");

    const submitting = ref<boolean>(false);
    const submitted = ref<boolean>(false);
    const failed = ref<boolean>(false);

    const back = () => {
      router.go(-1);
      // router.push({ name: Routes.Step6 });
    };

    const next = () => {
      submitting.value = true;

      if (!store.state.isTest) {
        axios
          .post(process.env.VUE_APP_EVOLVE_API_URL + "submitCANform", {
            email: store.state.step1.email,
            data: store.getters.applicationData,
          })
          .then((response) => {
            submitted.value = true;
            // result = response.data;
          })
          .catch((error) => {
            failed.value = true;
          });
      } else {
        submitted.value = true;
      }
    };

    return {
      back,
      next,
      submitting,
      submitted,
      failed,
    };
  },
});
</script>
