
import { clamp } from "@/assets/scripts/helpers";
import { computed, defineComponent, onMounted, ref } from "vue";
import filters from "@/filters";
export default defineComponent({
  name: "VS-Input",
  props: {
    id: String,
    type: { type: String, default: "number" },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 1000000 },
    step: { default: "any" },
    dp: { type: Number, default: -1 },
    onlyRoundAfterChange: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    value: Number,
    filter: String,
  },
  emits: ["change"],
  setup(props, { emit }) {
    const inputRef = ref<HTMLInputElement | null>(null);
    const isFocussed = ref<boolean>(false);

    onMounted(() => {
      console.log("loaded");
      console.log(props.value);
    });

    const formatted = computed(() => {
      switch (props.filter) {
        case "currency":
          return filters.currency(props.value as number);
        case "percentage":
          return filters.percentage(props.value as number);
      }

      return props.value;
    });

    const focus = () => {
      const iOS =
        !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
      if (iOS) {
        inputRef.value?.setSelectionRange(0, 9999);
      } else {
        inputRef.value?.select();
      }
      isFocussed.value = true;
    };

    const blur = (event: any) => {
      change(event);
      isFocussed.value = false;
    };

    const input = (event: any) => {
      if (
        inputRef.value &&
        props.dp !== -1 &&
        !props.onlyRoundAfterChange &&
        countDecimals(+inputRef.value.value) > props.dp
      ) {
        inputRef.value.valueAsNumber = +trimDP(+inputRef.value.value, props.dp);
      }
    };

    const change = (event: any) => {
      console.log(event);
      if (inputRef.value) {
        if (props.dp !== -1) {
          inputRef.value.valueAsNumber = +trimDP(
            +inputRef.value.value,
            props.dp
          );
        }
        const value = clamp(+event.target.value, props.min, props.max);
        emit("change", value);
      }
    };

    const trimDP = (x: number, dp = 0) => {
      if (dp === 0) {
        return Math.floor(+x).toString();
      }

      dp = Math.pow(10, dp || 2);
      return (Math.floor(+x * dp) / dp).toString();
    };

    const countDecimals = (x: number) => {
      if (Math.floor(x) !== x) {
        return x.toString().split(".")[1].length || 0;
      }

      return 0;
    };

    return {
      inputRef,
      isFocussed,
      focus,
      blur,
      input,
      change,
      formatted,
    };
  },
});
