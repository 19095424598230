<template>
  <h1 class="my-6 text-3xl">
    Retirement Method: {{ state.data.optionSelected }}
  </h1>

  <div>
    <template v-if="state.data.optionSelected === RetirementDecision.Drawdown">
      <div class="input-wrapper">
        <label>Crystal Drawdown Option Selected</label>
        <p class="input">{{ state.data.drawdownStrategy }}</p>
      </div>

      <div class="input-wrapper">
        <label>Total Fund Value</label>
        <p class="input">{{ filters.currency(state.data.totalFundValue) }}</p>
      </div>

      <div class="input-wrapper">
        <label>To receive a Tax Free Lump Sum of</label>
        <p class="input">{{ filters.currency(state.data.taxFreeLumpSum) }}</p>
      </div>

      <div class="input-wrapper">
        <label>Followed by Gross Annual Income of</label>
        <p class="input">
          {{ filters.currency(state.data.grossAnnualIncome) }}
        </p>
      </div>

      <div class="input-wrapper">
        <label
          >Amount of monthly income (This income will be paid monthly)</label
        >
        <p class="input">{{ filters.currency(state.data.monthlyIncome) }}</p>
      </div>

      <div class="input-wrapper">
        <label>This includes a monthly tax free element of</label>
        <p class="input">{{ filters.currency(state.data.taxFreeElement) }}</p>
      </div>

      <div class="input-wrapper">
        <label
          >Based on the above withdrawals, your expected fund at 75 is estimated
          to be</label
        >
        <p class="input">
          {{ filters.currency(state.data.estimatedFundValueAt75) }}
        </p>
      </div>
    </template>

    <template
      v-else-if="
        state.data.optionSelected === RetirementDecision.UncrystalisedLumpSum ||
        state.data.optionSelected === RetirementDecision.LumpSum
      "
    >
      <div class="input-wrapper">
        <label>Value of your Account at DD MM YYYY</label>
        <p class="input">{{ filters.currency(state.data.totalFundValue) }}</p>
      </div>

      <div class="input-wrapper">
        <label>25% tax-free cash amount</label>
        <p class="input">
          {{ filters.currency(state.data.twentyFivePercentTaxFreeCash) }}
        </p>
      </div>
    </template>

    <template
      v-if="
        state.data.optionSelected === RetirementDecision.UncrystalisedLumpSum
      "
    >
      <div class="input-wrapper">
        <label>Total taxable amount</label>
        <p class="input">
          {{ filters.currency(state.data.totalTaxableAmount) }}
        </p>
      </div>

      <div class="input-wrapper">
        <label
          >Tax due on {{ filters.currency(state.data.taxDueOn20Percent) }} at
          20%</label
        >
        <p class="input">
          {{ filters.currency(state.data.taxAt20Percent) }}
        </p>
      </div>

      <div class="input-wrapper">
        <label
          >Tax due on {{ filters.currency(state.data.taxDueOn40Percent) }} at
          40%</label
        >
        <p class="input">
          {{ filters.currency(state.data.taxAt40Percent) }}
        </p>
      </div>

      <div class="input-wrapper">
        <label
          >Tax due on {{ filters.currency(state.data.taxDueOn45Percent) }} at
          45%</label
        >
        <p class="input">
          {{ filters.currency(state.data.taxAt45Percent) }}
        </p>
      </div>
    </template>

    <template
      v-else-if="state.data.optionSelected === RetirementDecision.LumpSum"
    >
      <div class="input-wrapper">
        <label>20% tax on £xxxx</label>
        <p class="input">{{ filters.currency(state.data.taxAt20Percent) }}</p>
      </div>

      <div class="input-wrapper">
        <label>Your net payment after tax</label>
        <p class="input">{{ filters.currency(state.data.netPayment) }}</p>
      </div>
    </template>

    <div class="input-wrapper">
      <div class="flex justify-between">
        <label class="cell shrink">Date of Birth (DD/MM/YYYY)</label>
        <tooltip
          content="To apply for Crystal Drawdown you must be 55 or over.
"
        >
          <i class="fas fa-info-circle"></i>
        </tooltip>
      </div>
      <p class="input">{{ state.data.dateOfBirth }}</p>
    </div>
  </div>

  <h2 class="header">
    Step {{ getStepNumber }} of {{ getters.numberOfSteps }}: Personal Details
  </h2>

  <form @submit.prevent="next()">
    <div class="input-wrapper required">
      <label for="title">Title </label>
      <select id="title" class="input" v-model="title" required>
        <option disabled selected value="">Select</option>
        <option>Brigadier</option>
        <option>Captain</option>
        <option>Colonel</option>
        <option>Commander</option>
        <option>Dame</option>
        <option>Dr</option>
        <option>Earl</option>
        <option>Esquire</option>
        <option>Lady</option>
        <option>Lieutenant Commander</option>
        <option>Lord</option>
        <option>Major</option>
        <option>Major General</option>
        <option>Master</option>
        <option>Miss</option>
        <option>Mr</option>
        <option>Mrs</option>
        <option>Ms</option>
        <option>Mx</option>
        <option>Professor</option>
        <option>Reverend</option>
        <option>Right Honourable</option>
        <option>Sir</option>
        <option>Sister</option>
      </select>
    </div>

    <div class="input-wrapper required">
      <label for="forename">Forename(s)</label>
      <input
        id="forename"
        class="input"
        type="text"
        v-model="forenames"
        required
      />
    </div>

    <div class="input-wrapper required">
      <label for="surname">Surname</label>
      <input
        id="surname"
        class="input"
        type="text"
        v-model="surname"
        required
      />
    </div>

    <div class="input-wrapper required">
      <label for="ni-number">NI Number</label>
      <input
        id="ni-number"
        class="input"
        type="text"
        v-model="niNumber"
        required
        pattern="^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\s*\d\s*){6}([A-D]|\s)$"
      />
    </div>

    <div class="input-wrapper required">
      <label for="marital-status">Marital Status</label>
      <select
        id="marital-status"
        class="input"
        v-model="maritalStatus"
        required
      >
        <option disabled selected value="">Select</option>
        <option>Single</option>
        <option>Married</option>
      </select>
    </div>

    <div class="input-wrapper required">
      <div class="flex justify-between">
        <label for="email" class="cell shrink">Email address</label>
        <tooltip content="All communication where possible will be via email.">
          <i class="fas fa-info-circle"></i>
        </tooltip>
      </div>

      <input id="email" class="input" type="email" v-model="email" required />
    </div>

    <div class="button-group">
      <button type="button" class="button disabled" @click="back()">
        <i class="fas fa-arrow-left"></i> Back
      </button>
      <button type="submit" class="button">
        Next <i class="fas fa-arrow-right"></i>
      </button>
    </div>
  </form>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import store, { RetirementDecision } from "@/store/";
import router, { Routes } from "@/router";
import filters from "@/filters";
// import { isValidNINumber } from "@/assets/scripts/formValidation";

export default defineComponent({
  name: "Step 1",
  components: {},
  setup() {
    const back = () => {
      console.log("back");
    };
    const next = () => {
      //console.log(store.state.step1.niNumber);
      //console.log();

      // isValidNINumber(store.state.step1.niNumber)

      router.push(Routes.Step2);
      console.log("done");
    };

    const getStepNumber = computed(() => {
      return 1;
    });

    return {
      back,
      next,
      state: store.state,
      getters: store.getters,
      filters,
      RetirementDecision,
      getStepNumber,
    };
  },
  computed: {
    title: {
      get() {
        return store.state.step1.title;
      },
      set(value) {
        store.commit("setTitle", value);
      },
    },
    forenames: {
      get() {
        return store.state.step1.forename;
      },
      set(value) {
        store.commit("setForename", value);
      },
    },
    surname: {
      get() {
        return store.state.step1.surname;
      },
      set(value) {
        store.commit("setSurname", value);
      },
    },
    niNumber: {
      get() {
        return store.state.step1.niNumber;
      },
      set(value) {
        store.commit("setNINumber", value);
      },
    },
    maritalStatus: {
      get() {
        return store.state.step1.maritalStatus;
      },
      set(value) {
        store.commit("setMaritalStatus", value);
      },
    },
    email: {
      get() {
        return store.state.step1.email;
      },
      set(value) {
        store.commit("setEmail", value);
      },
    },
  },
});
</script>
