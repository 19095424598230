<template>
  <h2 class="header">
    Step {{ getStepNumber }} of {{ getters.numberOfSteps }}: Contact Details
  </h2>

  <form @submit.prevent="next()">
    <div class="input-wrapper required">
      <label for="telephone-number">Telephone number</label>
      <input
        id="telephone-number"
        class="input"
        type="tel"
        required
        v-model="telephoneNumber"
        pattern="^(?:0|\+?44)(?:\d\s?){9,10}$"
      />
    </div>

    <div class="input-wrapper required">
      <label for="address-line1">Address line 1</label>
      <input
        id="address-line1"
        class="input"
        type="text"
        required
        v-model="line1"
        autocomplete="none"
      />
    </div>

    <div class="input-wrapper">
      <label for="address-line2">Address line 2</label>
      <input
        id="address-line2"
        class="input"
        type="text"
        v-model="line2"
        autocomplete="none"
      />
    </div>

    <div class="input-wrapper">
      <label for="city">City</label>
      <input
        id="city"
        class="input"
        type="text"
        required
        v-model="city"
        autocomplete="none"
      />
    </div>

    <div class="input-wrapper">
      <label for="county">County</label>
      <input
        id="county"
        class="input"
        type="text"
        v-model="county"
        autocomplete="none"
      />
    </div>

    <div class="input-wrapper required">
      <label for="postcode">Postcode</label>
      <input
        id="postcode"
        class="input"
        type="text"
        required
        v-model="postcode"
        autocomplete="none"
        pattern="([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})"
      />
    </div>

    <div class="input-wrapper">
      <label for="country">Country</label>
      <input
        id="country"
        class="input"
        type="text"
        v-model="country"
        autocomplete="none"
      />
    </div>

    <div class="button-group">
      <button type="button" class="button" @click="back()">
        <i class="fas fa-arrow-left"></i> Back
      </button>
      <button type="submit" class="button">
        Next <i class="fas fa-arrow-right"></i>
      </button>
    </div>
  </form>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from "vue";
import store from "@/store/";
import router, { Routes } from "@/router";

export default defineComponent({
  name: "Step 2",
  components: {},
  setup() {
    onMounted(() => {
      attachLoqate();
    });
    const attachLoqate = () => {
      const pca: any = (window as any).pca;

      const fields = [
        { element: "address-line1", field: "Line1" },
        { element: "address-line2", field: "Line2" },
        { element: "city", field: "City" },
        { element: "county", field: "Province" },
        { element: "postcode", field: "PostalCode" },
        { element: "country", field: "CountryName" },
      ];

      const control = new pca.Address(fields, {
        key: store.state.control.loqate,
        suppressAutocomplete: false,
      });

      control.listen("populate", (address: any) => {
        store.commit("setAddressLine1", address.FormattedLine1);
        store.commit("setAddressLine2", address.FormattedLine2);
        store.commit("setAddressCity", address.City);
        store.commit("setAddressCounty", address.Province);
        store.commit("setAddressPostCode", address.PostalCode);
        store.commit("setAddressCountry", address.CountryName);
      });
    };
    const back = () => {
      router.go(-1);
      // router.push({ name: Routes.Step1 });
    };
    const next = () => {
      router.push({ name: Routes.Step3 });
    };
    const getStepNumber = computed(() => {
      return 2;
    });

    return { back, next, getters: store.getters, getStepNumber };
  },
  computed: {
    telephoneNumber: {
      get() {
        return store.state.step2.telephoneNumber;
      },
      set(value) {
        store.commit("setTelephoneNumber", value);
      },
    },
    line1: {
      get() {
        return store.state.step2.address.line1;
      },
      set(value) {
        store.commit("setAddressLine1", value);
      },
    },
    line2: {
      get() {
        return store.state.step2.address.line2;
      },
      set(value) {
        store.commit("setAddressLine2", value);
      },
    },
    city: {
      get() {
        return store.state.step2.address.city;
      },
      set(value) {
        store.commit("setAddressCity", value);
      },
    },
    county: {
      get() {
        return store.state.step2.address.county;
      },
      set(value) {
        store.commit("setAddressCounty", value);
      },
    },
    postcode: {
      get() {
        return store.state.step2.address.postCode;
      },
      set(value) {
        store.commit("setAddressPostCode", value);
      },
    },
    country: {
      get() {
        return store.state.step2.address.country;
      },
      set(value) {
        store.commit("setAddressCountry", value);
      },
    },
  },
});
</script>
