
import { computed, defineComponent, ref, onMounted } from "vue";
import store, { RetirementDecision } from "@/store/";
import router, { Routes } from "@/router";

export default defineComponent({
  name: "Step 4",
  components: {},
  setup() {
    const sortCodeRef = ref<HTMLInputElement>();

    onMounted(() => {
      const sortCodeInput = sortCodeRef.value;

      if (sortCodeInput) {
        sortCodeInput.addEventListener("input", (event) => {
          if (sortCodeInput.validity.patternMismatch) {
            sortCodeInput.setCustomValidity(
              "Please enter a sort code in the format XX-XX-XX."
            );
          } else {
            sortCodeInput.setCustomValidity("");
          }
        });
      }
    });

    const back = () => {
      router.go(-1);
      // router.push({ name: Routes.Step3 });
    };
    const next = () => {
      if (store.state.data.totalFundValue >= 10000) {
        router.push({ name: Routes.Step5 });
      } else if (
        store.state.data.optionSelected === RetirementDecision.Drawdown
      ) {
        router.push({ name: Routes.Step6 });
      } else {
        router.push({ name: Routes.Submit });
      }
    };
    const getStepNumber = computed(() => {
      return 4;
    });
    return {
      back,
      next,
      sortCodeRef,
      getters: store.getters,
      getStepNumber,
    };
  },
  computed: {
    accountNumber: {
      get() {
        return store.state.step4.accountNumber;
      },
      set(value) {
        store.commit("setAccountNumber", value);
      },
    },
    sortCode: {
      get() {
        return store.state.step4.sortCode;
      },
      set(value) {
        store.commit("setSortCode", value);
      },
    },
    rollNumber: {
      get() {
        return store.state.step4.rollNumber;
      },
      set(value) {
        store.commit("setRollNumber", value);
      },
    },
    accountHolderName: {
      get() {
        return store.state.step4.accountHolderName;
      },
      set(value) {
        store.commit("setAccountHolderName", value);
      },
    },
  },
});
