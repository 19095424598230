
import { defineComponent, onMounted, onBeforeUnmount } from "vue";
import { firebase, ui } from "@/plugins/firebase";
import store from "@/store/";
import router, { Routes } from "@/router";

export default defineComponent({
  name: "App",
  components: {},
  setup() {
    let unsubscribeFunction: any;

    onMounted(() => {
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
          ui.start("#firebaseui-auth-container", {
            signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
            // Other config options...
          });
        });

      unsubscribeFunction = firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          await firebase
            .auth()
            .currentUser?.getIdToken()
            .then((idToken) => {
              store.commit("setAdminUID", idToken);

              router.push({ name: Routes.Applications });
            });
        }
      });
    });

    onBeforeUnmount(() => {
      unsubscribeFunction();
    });
  },
});
