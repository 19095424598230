<template>
  <h1 class="my-6 text-3xl text-center">
    Application: {{ applicationReference }}
  </h1>

  <table class="w-full mb-4">
    <tbody>
      <!-- Data -->
      <tr>
        <td colspan="2" class="font-bold text-center">
          Data (submitted by client, not verified)
        </td>
      </tr>

      <tr>
        <td>Total Fund Value</td>
        <td>{{ filters.currency(state.data.totalFundValue) }}</td>
      </tr>

      <tr>
        <td>Tax Free Lump Sum</td>
        <td>{{ filters.currency(state.data.taxFreeLumpSum) }}</td>
      </tr>

      <tr>
        <td>Gross Annual Income</td>
        <td>{{ filters.currency(state.data.grossAnnualIncome) }}</td>
      </tr>

      <tr>
        <td>Monthly Income</td>
        <td>{{ filters.currency(state.data.monthlyIncome) }}</td>
      </tr>

      <tr>
        <td>Monthly Tax Free Element</td>
        <td>{{ filters.currency(state.data.taxFreeElement) }}</td>
      </tr>

      <tr>
        <td>Expected fund at 75</td>
        <td>{{ filters.currency(state.data.estimatedFundValueAt75) }}</td>
      </tr>

      <tr>
        <td>Date of Birth</td>
        <td>{{ state.data.dateOfBirth }}</td>
      </tr>

      <!-- Step 1 -->
      <tr>
        <td colspan="2" class="font-bold text-center">Step 1</td>
      </tr>

      <tr>
        <td>Title</td>
        <td>{{ state.step1.title }}</td>
      </tr>

      <tr>
        <td>Surname</td>
        <td>{{ state.step1.surname }}</td>
      </tr>

      <tr>
        <td>Forename</td>
        <td>{{ state.step1.forename }}</td>
      </tr>

      <tr>
        <td>NI Number</td>
        <td>{{ state.step1.niNumber }}</td>
      </tr>

      <tr>
        <td>Marital Status</td>
        <td>{{ state.step1.maritalStatus }}</td>
      </tr>

      <tr>
        <td>Email</td>
        <td>{{ state.step1.email }}</td>
      </tr>

      <!-- Step 2 -->
      <tr>
        <td colspan="2" class="font-bold text-center">Step 2</td>
      </tr>

      <tr>
        <td>Telephone Number</td>
        <td>{{ state.step2.telephoneNumber }}</td>
      </tr>
      <tr>
        <td>Line 1</td>
        <td>{{ state.step2.address.line1 }}</td>
      </tr>
      <tr>
        <td>Line 2</td>
        <td>{{ state.step2.address.line2 }}</td>
      </tr>
      <tr>
        <td>City</td>
        <td>{{ state.step2.address.city }}</td>
      </tr>
      <tr>
        <td>County</td>
        <td>{{ state.step2.address.county }}</td>
      </tr>
      <tr>
        <td>Country</td>
        <td>{{ state.step2.address.country }}</td>
      </tr>
      <tr>
        <td>Postcode</td>
        <td>{{ state.step2.address.postCode }}</td>
      </tr>

      <!-- Step 3 -->
      <tr>
        <td colspan="2" class="font-bold text-center">Step 3</td>
      </tr>

      <tr>
        <td>Scheme Name</td>
        <td>{{ state.step3.schemeName }}</td>
      </tr>
      <tr>
        <td>Policy Number</td>
        <td>{{ state.step3.policyNumber }}</td>
      </tr>
      <tr>
        <td>Scheme Administrator</td>
        <td>{{ state.step3.schemeAdministrator }}</td>
      </tr>

      <tr>
        <td>Scheme Administrator Address Line 1</td>
        <td>{{ state.step3.schemeAdministratorAddress.line1 }}</td>
      </tr>
      <tr>
        <td>Scheme Administrator Address Line 2</td>
        <td>{{ state.step3.schemeAdministratorAddress.line2 }}</td>
      </tr>
      <tr>
        <td>Scheme Administrator Address City</td>
        <td>{{ state.step3.schemeAdministratorAddress.city }}</td>
      </tr>
      <tr>
        <td>Scheme Administrator Address County</td>
        <td>{{ state.step3.schemeAdministratorAddress.county }}</td>
      </tr>
      <tr>
        <td>Scheme Administrator Address Postcode</td>
        <td>{{ state.step3.schemeAdministratorAddress.postCode }}</td>
      </tr>
      <tr>
        <td>Scheme Administrator Address Country</td>
        <td>{{ state.step3.schemeAdministratorAddress.country }}</td>
      </tr>

      <!-- Step 4 -->
      <tr>
        <td colspan="2" class="font-bold text-center">Step 4</td>
      </tr>

      <tr>
        <td>Account Number</td>
        <td>{{ state.step4.accountNumber }}</td>
      </tr>
      <tr>
        <td>Sort Code</td>
        <td>{{ state.step4.sortCode }}</td>
      </tr>
      <tr>
        <td>Roll Number</td>
        <td>{{ state.step4.rollNumber }}</td>
      </tr>
      <tr>
        <td>Account Holder Name</td>
        <td>{{ state.step4.accountHolderName }}</td>
      </tr>

      <!-- Step 5 -->
      <tr>
        <td colspan="2" class="font-bold text-center">Step 5</td>
      </tr>

      <tr>
        <td>Benefits Exceed Lifetime Allowance</td>
        <td>{{ state.step5.benefitsExceedLifetimeAllowance }}</td>
      </tr>

      <tr>
        <td>In Receipt Of Benefits Before 2006</td>
        <td>{{ state.step5.inReceiptOfBenefitsBefore2006 }}</td>
      </tr>

      <template v-if="state.step5.inReceiptOfBenefitsBefore2006 === 'Yes'">
        <tr>
          <td>Scheme Name</td>
          <td>
            {{ state.step5.inReceiptOfBenefitsBefore2006Data.schemeName }}
          </td>
        </tr>

        <tr>
          <td>Current Annual Income</td>
          <td>
            {{
              state.step5.inReceiptOfBenefitsBefore2006Data.currentAnnualIncome
            }}
          </td>
        </tr>

        <tr>
          <td>Date Benefit Commenced</td>
          <td>
            {{
              state.step5.inReceiptOfBenefitsBefore2006Data.dateBenefitCommenced
            }}
          </td>
        </tr>
      </template>

      <tr>
        <td>In Receipt Of Benefits After 2006</td>
        <td>{{ state.step5.inReceiptOfBenefitsAfter2006 }}</td>
      </tr>

      <template v-if="state.step5.inReceiptOfBenefitsAfter2006 === 'Yes'">
        <tr>
          <td>Date Commenced</td>
          <td>
            {{ state.step5.inReceiptOfBenefitsAfter2006Data.dateCommenced }}
          </td>
        </tr>

        <tr>
          <td>Scheme Name</td>
          <td>{{ state.step5.inReceiptOfBenefitsAfter2006Data.schemeName }}</td>
        </tr>
        <tr>
          <td>LTA Percentage</td>
          <td>
            {{ state.step5.inReceiptOfBenefitsAfter2006Data.ltaPercentage }}
          </td>
        </tr>
      </template>

      <!-- Step 6 -->
      <tr>
        <td colspan="2" class="font-bold text-center">Step 6</td>
      </tr>

      <slot v-for="(nominee, index) in state.step6.nominees" :key="index">
        <tr>
          <td colspan="2" class="font-bold">Nominee {{ index }}</td>
        </tr>

        <tr>
          <td>Name</td>
          <td>{{ nominee.fullName }}</td>
        </tr>

        <tr>
          <td>Relationship</td>
          <td>{{ nominee.relationship }}</td>
        </tr>

        <tr>
          <td>Percentage</td>
          <td>{{ filters.percentage(nominee.percentage) }}</td>
        </tr>

        <tr>
          <td>Nominee Address Line 1</td>
          <td>{{ nominee.addressLine1 }}</td>
        </tr>

        <tr>
          <td>Nominee Address Line 2</td>
          <td>{{ nominee.addressLine2 }}</td>
        </tr>

        <tr>
          <td>Nominee Address City</td>
          <td>{{ nominee.addressCity }}</td>
        </tr>

        <tr>
          <td>Nominee Address County</td>
          <td>{{ nominee.addressCounty }}</td>
        </tr>

        <tr>
          <td>Nominee Address County</td>
          <td>{{ nominee.addressCounty }}</td>
        </tr>

        <tr>
          <td>Nominee Address Country</td>
          <td>{{ nominee.addressCountry }}</td>
        </tr>

        <tr>
          <td>Nominee Address Postcode</td>
          <td>{{ nominee.addressPostCode }}</td>
        </tr>
      </slot>
    </tbody>
  </table>

  <button class="flex-shrink button" @click="back()">
    <i class="mr-2 fas fa-arrow-left"></i>Back
  </button>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import store from "@/store/";
import { useRoute } from "vue-router";
import router, { Routes } from "@/router";
import axios from "axios";
import filters from "@/filters";

export default defineComponent({
  name: "Application",
  components: {},
  setup() {
    const route = useRoute();
    const applicationReference = ref<string>(route.params.reference as string);

    const back = () => {
      router.push({
        name: Routes.Applications,
      });
    };

    onMounted(() => {
      store.commit("clearApplicationData");

      // Generate some random applications for testing.
      axios
        .post(process.env.VUE_APP_EVOLVE_API_URL + "getCANApplication", {
          idToken: store.state.admin.uid,
          reference: applicationReference.value,
        })
        .then((response) => {
          if (response.data) {
            store.commit("setApplicationData", response.data);
          }

          console.log(response);
          // applications.value = response.data;
          // result = response.data;
        });

      // Get application information.
    });

    return { applicationReference, state: store.state, filters, back };
  },
});
</script>
