
import { defineComponent, ref } from "vue";
import store from "@/store/";
import router, { Routes } from "@/router";
import axios from "axios";

export default defineComponent({
  name: "Step 6",
  components: {},
  setup() {
    const error = ref<string>("");

    const submitting = ref<boolean>(false);
    const submitted = ref<boolean>(false);
    const failed = ref<boolean>(false);

    const back = () => {
      router.go(-1);
      // router.push({ name: Routes.Step6 });
    };

    const next = () => {
      submitting.value = true;

      if (!store.state.isTest) {
        axios
          .post(process.env.VUE_APP_EVOLVE_API_URL + "submitCANform", {
            email: store.state.step1.email,
            data: store.getters.applicationData,
          })
          .then((response) => {
            submitted.value = true;
            // result = response.data;
          })
          .catch((error) => {
            failed.value = true;
          });
      } else {
        submitted.value = true;
      }
    };

    return {
      back,
      next,
      submitting,
      submitted,
      failed,
    };
  },
});
