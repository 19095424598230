
import { computed, defineComponent } from "vue";
import store, { RetirementDecision } from "@/store/";
import router, { Routes } from "@/router";

export default defineComponent({
  name: "Step 5",
  components: {},
  setup() {
    const back = () => {
      router.go(-1);
      // router.push({ name: Routes.Step4 });
    };
    const next = () => {
      if (store.state.data.optionSelected === RetirementDecision.Drawdown) {
        router.push({ name: Routes.Step6 });
      } else {
        router.push({ name: Routes.Submit });
      }
    };
    const getStepNumber = computed(() => {
      return 5;
    });
    return {
      back,
      next,
      state: store.state,
      getters: store.getters,
      getStepNumber,
    };
  },
  computed: {
    benefitsExceedLifetimeAllowance: {
      get() {
        return store.state.step5.benefitsExceedLifetimeAllowance;
      },
      set(value) {
        store.commit("setBenefitsExceedLifetimeAllowance", value);
      },
    },
    inReceiptOfBenefitsBefore2006: {
      get() {
        return store.state.step5.inReceiptOfBenefitsBefore2006;
      },
      set(value) {
        store.commit("setInReceiptOfBenefitsBefore2006", value);
      },
    },
    benefitsBefore2006SchemeName: {
      get() {
        return store.state.step5.inReceiptOfBenefitsBefore2006Data.schemeName;
      },
      set(value) {
        store.commit("setInReceiptOfBenefitsBefore2006SchemeName", value);
      },
    },
    benefitsBefore2006CurrentAnnualIncome: {
      get() {
        return store.state.step5.inReceiptOfBenefitsBefore2006Data
          .currentAnnualIncome;
      },
      set(value) {
        store.commit(
          "setInReceiptOfBenefitsBefore2006CurrentAnnualIncome",
          value
        );
      },
    },
    benefitsBefore2006DataBenefitCommenced: {
      get() {
        return store.state.step5.inReceiptOfBenefitsBefore2006Data
          .dateBenefitCommenced;
      },
      set(value) {
        store.commit(
          "setInReceiptOfBenefitsBefore2006DateBenefitCommenced",
          value
        );
      },
    },
    inReceiptOfBenefitsAfter2006: {
      get() {
        return store.state.step5.inReceiptOfBenefitsAfter2006;
      },
      set(value) {
        store.commit("setInReceiptOfBenefitsAfter2006", value);
      },
    },
    benefitsAfter2006SchemeName: {
      get() {
        return store.state.step5.inReceiptOfBenefitsAfter2006Data.schemeName;
      },
      set(value) {
        store.commit("setInReceiptOfBenefitsAfter2006SchemeName", value);
      },
    },
    benefitsAfter2006DateCommenced: {
      get() {
        return store.state.step5.inReceiptOfBenefitsAfter2006Data.dateCommenced;
      },
      set(value) {
        store.commit("setInReceiptOfBenefitsAfter2006DateCommenced", value);
      },
    },
    benefitsAfter2006LTAPercentage: {
      get() {
        return store.state.step5.inReceiptOfBenefitsAfter2006Data.ltaPercentage;
      },
      set(value) {
        store.commit("setInReceiptOfBenefitsAfter2006LTAPercentage", value);
      },
    },
    registeredForProtectionOrEnhancementToLifetimeAllowance: {
      get() {
        return store.state.step5
          .registeredForProtectionOrEnhancementToLifetimeAllowance;
      },
      set(value) {
        store.commit(
          "setRegisteredForProtectionOrEnhancementToLifetimeAllowance",
          value
        );
      },
    },
    primaryProtection: {
      get() {
        return store.state.step5.primaryProtection;
      },
      set(value) {
        store.commit("setPrimaryProtection", value);
      },
    },
    fixedProtection: {
      get() {
        return store.state.step5.fixedProtection;
      },
      set(value) {
        store.commit("setFixedProtection", value);
      },
    },
    enhancedProtection: {
      get() {
        return store.state.step5.enhancedProtection;
      },
      set(value) {
        store.commit("setEnhancedProtection", value);
      },
    },
    lifetimeAllowanceEnhancementFactors: {
      get() {
        return store.state.step5.lifetimeAllowanceEnhancementFactors;
      },
      set(value) {
        store.commit("setLifetimeAllowanceEnhancementFactors", value);
      },
    },
  },
});
