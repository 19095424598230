
import { computed, defineComponent } from "vue";
import store, { RetirementDecision } from "@/store/";
import router, { Routes } from "@/router";
import filters from "@/filters";
// import { isValidNINumber } from "@/assets/scripts/formValidation";

export default defineComponent({
  name: "Step 1",
  components: {},
  setup() {
    const back = () => {
      console.log("back");
    };
    const next = () => {
      //console.log(store.state.step1.niNumber);
      //console.log();

      // isValidNINumber(store.state.step1.niNumber)

      router.push(Routes.Step2);
      console.log("done");
    };

    const getStepNumber = computed(() => {
      return 1;
    });

    return {
      back,
      next,
      state: store.state,
      getters: store.getters,
      filters,
      RetirementDecision,
      getStepNumber,
    };
  },
  computed: {
    title: {
      get() {
        return store.state.step1.title;
      },
      set(value) {
        store.commit("setTitle", value);
      },
    },
    forenames: {
      get() {
        return store.state.step1.forename;
      },
      set(value) {
        store.commit("setForename", value);
      },
    },
    surname: {
      get() {
        return store.state.step1.surname;
      },
      set(value) {
        store.commit("setSurname", value);
      },
    },
    niNumber: {
      get() {
        return store.state.step1.niNumber;
      },
      set(value) {
        store.commit("setNINumber", value);
      },
    },
    maritalStatus: {
      get() {
        return store.state.step1.maritalStatus;
      },
      set(value) {
        store.commit("setMaritalStatus", value);
      },
    },
    email: {
      get() {
        return store.state.step1.email;
      },
      set(value) {
        store.commit("setEmail", value);
      },
    },
  },
});
