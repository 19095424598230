<template>
  <div class="flex items-center justify-center">
    <h1 class="flex-grow my-6 text-3xl text-center">Applications</h1>
    <div>
      <button class="flex-shrink button" @click="logOut()">Log out</button>
    </div>
  </div>

  <table class="w-full mb-4 table-auto">
    <thead>
      <tr>
        <th
          :class="{
            sorted: currentSort === 'reference',
            asc: currentSortDir === 'asc',
            desc: currentSortDir === 'desc',
          }"
        >
          Reference
        </th>
        <th
          :class="{
            sorted: currentSort === 'date',
            asc: currentSortDir === 'asc',
            desc: currentSortDir === 'desc',
          }"
        >
          Date
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="application in sorted" :key="application.reference">
        <td>
          <div
            @click="open(application.reference)"
            class="underline cursor-pointer"
          >
            {{ application.reference }}
          </div>
        </td>
        <td>
          {{ application.date.toLocaleDateString("en-GB") }}
        </td>
      </tr>
    </tbody>
  </table>

  <div class="flex items-center justify-center">
    <button
      class="flex-shrink button"
      :class="{ disabled: currentPage <= 1 }"
      @click="prevPage"
    >
      <i class="mr-2 fas fa-arrow-left"></i>Previous
    </button>
    <p class="flex-grow text-center text-white text-bold">
      Page {{ currentPage }} of
      {{ Math.ceil(applications.length / pageSize) }}
    </p>
    <button
      class="flex-shrink button"
      :class="{ disabled: !isANextPage }"
      @click="nextPage"
    >
      Next<i class="ml-2 fas fa-arrow-right"></i>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed } from "vue";
import store from "@/store/";
import router, { Routes } from "@/router";
import axios from "axios";
import { firebase, ui } from "@/plugins/firebase";

interface Application {
  reference: string;
  date: Date;
}

export default defineComponent({
  name: "Applications",
  components: {},
  setup() {
    const currentSort = ref<string>("date");
    const currentSortDir = ref<string>("desc");
    const currentPage = ref<number>(1);

    const pageSize = 20;

    const applications = ref<Application[]>([]);

    const nextPage = () => {
      if (currentPage.value * pageSize < applications.value.length) {
        currentPage.value++;
      }
    };
    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };
    const isANextPage = computed((): boolean => {
      return currentPage.value * pageSize < applications.value.length;
    });

    const sorted = computed(() => {
      const array = applications.value;
      return array
        .sort((a: any, b: any) => {
          let modifier = 1;
          if (currentSortDir.value === "desc") {
            modifier = -1;
          }
          if (a[currentSort.value] < b[currentSort.value]) {
            return -1 * modifier;
          }
          if (a[currentSort.value] > b[currentSort.value]) {
            return 1 * modifier;
          }
          return 0;
        })
        .filter((row: any, index: number) => {
          const start = (currentPage.value - 1) * pageSize;
          const end = currentPage.value * pageSize;
          if (index >= start && index < end) {
            return true;
          }
        });
    });

    onMounted(() => {
      // Generate some random applications for testing.
      /* for (let i = 0; i < 100; i++) {
        applications.value.push({
          reference: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 10),
          date: new Date(+new Date() - Math.floor(Math.random() * 10000000000)),
        });
      } */

      axios
        .post(process.env.VUE_APP_EVOLVE_API_URL + "getCANApplications", {
          idToken: store.state.admin.uid,
        })
        .then((response) => {
          response.data.forEach((application: any) => {
            applications.value.push({
              reference: application.reference,
              date: new Date(application.date),
            });
          });
          console.log(response);
          // applications.value = response.data;
          // result = response.data;
        });

      if (!store.getters.isLoggedIn) {
        router.push({ name: Routes.Admin });
      }
    });

    const logOut = () => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          router.push({ name: Routes.Admin });
        });
    };

    const open = (reference: string) => {
      router.push({
        name: Routes.Application,
        params: { reference: reference },
      });
    };

    return {
      applications,
      open,
      nextPage,
      prevPage,
      isANextPage,
      sorted,
      currentPage,
      pageSize,
      currentSort,
      currentSortDir,
      logOut,
    };
  },
});
</script>
