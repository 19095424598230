<template>
  <h2 class="header">
    Step {{ getStepNumber }} of {{ getters.numberOfSteps }}: Payment Details
  </h2>

  <form @submit.prevent="next()">
    <div class="input-wrapper required">
      <div class="flex justify-between">
        <label for="account-number" class="cell shrink">Account number</label>
        <tooltip content="Your account number must be 8 digits.">
          <i class="fas fa-info-circle"></i>
        </tooltip>
      </div>

      <input
        id="account-number"
        class="input"
        placeholder="XXXXXXXX"
        type="text"
        maxlength="8"
        required
        v-model="accountNumber"
        pattern="^(\d){8}$"
      />
    </div>

    <div class="input-wrapper required">
      <label for="sort-code">Sort Code</label>
      <input
        id="sort-code"
        ref="sortCodeRef"
        placeholder="XX-XX-XX"
        class="input"
        type="text"
        maxlength="8"
        required
        v-model="sortCode"
        pattern="^(\d){2}-(\d){2}-(\d){2}$"
      />
    </div>

    <div class="input-wrapper">
      <div class="flex justify-between">
        <label for="roll-number" class="cell shrink">Roll Number</label>
        <tooltip
          content="You will only need to enter this information if you have a building society account number."
        >
          <i class="fas fa-info-circle"></i>
        </tooltip>
      </div>

      <input id="roll-number" class="input" type="text" v-model="rollNumber" />
    </div>

    <div class="input-wrapper required">
      <div class="flex justify-between">
        <label for="account-holder-name" class="cell shrink"
          >Account Holder Name</label
        >
        <tooltip
          content="Enter the name of the account holder. This should be an account in your name."
        >
          <i class="fas fa-info-circle"></i>
        </tooltip>
      </div>

      <input
        id="account-holder-name"
        class="input"
        type="text"
        required
        v-model="accountHolderName"
      />
    </div>

    <div class="button-group">
      <button type="button" class="button" @click="back()">
        <i class="fas fa-arrow-left"></i> Back
      </button>
      <button type="submit" class="button">
        Next <i class="fas fa-arrow-right"></i>
      </button>
    </div>
  </form>
</template>

<script lang="ts">
import { computed, defineComponent, ref, onMounted } from "vue";
import store, { RetirementDecision } from "@/store/";
import router, { Routes } from "@/router";

export default defineComponent({
  name: "Step 4",
  components: {},
  setup() {
    const sortCodeRef = ref<HTMLInputElement>();

    onMounted(() => {
      const sortCodeInput = sortCodeRef.value;

      if (sortCodeInput) {
        sortCodeInput.addEventListener("input", (event) => {
          if (sortCodeInput.validity.patternMismatch) {
            sortCodeInput.setCustomValidity(
              "Please enter a sort code in the format XX-XX-XX."
            );
          } else {
            sortCodeInput.setCustomValidity("");
          }
        });
      }
    });

    const back = () => {
      router.go(-1);
      // router.push({ name: Routes.Step3 });
    };
    const next = () => {
      if (store.state.data.totalFundValue >= 10000) {
        router.push({ name: Routes.Step5 });
      } else if (
        store.state.data.optionSelected === RetirementDecision.Drawdown
      ) {
        router.push({ name: Routes.Step6 });
      } else {
        router.push({ name: Routes.Submit });
      }
    };
    const getStepNumber = computed(() => {
      return 4;
    });
    return {
      back,
      next,
      sortCodeRef,
      getters: store.getters,
      getStepNumber,
    };
  },
  computed: {
    accountNumber: {
      get() {
        return store.state.step4.accountNumber;
      },
      set(value) {
        store.commit("setAccountNumber", value);
      },
    },
    sortCode: {
      get() {
        return store.state.step4.sortCode;
      },
      set(value) {
        store.commit("setSortCode", value);
      },
    },
    rollNumber: {
      get() {
        return store.state.step4.rollNumber;
      },
      set(value) {
        store.commit("setRollNumber", value);
      },
    },
    accountHolderName: {
      get() {
        return store.state.step4.accountHolderName;
      },
      set(value) {
        store.commit("setAccountHolderName", value);
      },
    },
  },
});
</script>
