
import { defineComponent, ref } from "vue";
export default defineComponent({
  name: "Accordion",
  setup() {
    const headerRef = ref<HTMLElement | null>(null);
    const contentRef = ref<HTMLElement | null>(null);
    const maxHeight = ref<number>(0);
    const contentActive = ref<boolean>(false);
    const onClick = () => {
      contentActive.value = !contentActive.value;
      if (contentRef.value) {
        maxHeight.value = contentActive.value
          ? contentRef.value.scrollHeight
          : 0;
      }
    };
    return {
      headerRef,
      contentRef,
      contentActive,
      maxHeight,
      onClick,
    };
  },
});
