
import { computed, defineComponent, ref, onBeforeUnmount, nextTick } from "vue";
import store, { Nominee } from "@/store/";
import router, { Routes } from "@/router";

export default defineComponent({
  name: "Step 6",
  components: {},
  setup() {
    const error = ref<string>("");
    const nominees = ref<Nominee[]>(store.state.step6.nominees);

    onBeforeUnmount(() => {
      store.commit("setNominees", nominees.value);
    });

    const addNominee = () => {
      if (nominees.value.length < 10) {
        nominees.value.push(new Nominee());

        nextTick(() => {
          const pca: any = (window as any).pca;
          const nomineeValue = nominees.value.length - 1;

          const fields = [
            {
              element: `nomineeAddressLine1-${nomineeValue}`,
              field: "Line1",
            },
            {
              element: `nomineeAddressLine2-${nomineeValue}`,
              field: "Line2",
            },
            {
              element: `nomineeAddressCity-${nomineeValue}`,
              field: "City",
            },
            {
              element: `nomineeAddressCounty-${nomineeValue}`,
              field: "Province",
            },
            {
              element: `nomineeAddressPostcode-${nomineeValue}`,
              field: "PostalCode",
            },
            {
              element: `nomineeAddressCountry-${nomineeValue}`,
              field: "CountryName",
            },
          ];

          const control = new pca.Address(fields, {
            key: store.state.control.loqate,
            suppressAutocomplete: false,
          });

          control.listen("populate", (address: any) => {
            nominees.value[nomineeValue].addressLine1 = address.FormattedLine1;
            nominees.value[nomineeValue].addressLine2 = address.FormattedLine2;
            nominees.value[nomineeValue].addressCity = address.City;
            nominees.value[nomineeValue].addressCounty = address.Province;
            nominees.value[nomineeValue].addressPostCode = address.PostalCode;
            nominees.value[nomineeValue].addressCountry = address.CountryName;
          });
        });

        error.value = "";
      } else {
        error.value = "You already have the maximum number of nominees (10)";
      }
    };

    const removeNominee = (index: number) => {
      nominees.value.splice(index, 1);
      error.value = "";
    };

    const back = () => {
      router.go(-1);
      // router.push({ name: Routes.Step5 });
    };

    const next = () => {
      if (nominees.value.length > 0) {
        const total = nominees.value
          .map((i) => i.percentage)
          .reduce((a, b) => a + b);

        if (total === 100) {
          router.push({ name: Routes.Submit });
        } else {
          error.value = "Allocation must equal 100%";
        }
      } else {
        error.value = "You must nominate at least 1 beneficiary.";
      }
    };

    const getStepNumber = computed(() => {
      return store.state.data.totalFundValue < 10000 ? 5 : 6;
    });

    return {
      addNominee,
      removeNominee,
      nominees,
      back,
      next,
      error,
      getters: store.getters,
      getStepNumber,
    };
  },
});
