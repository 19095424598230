<template>
  <!-- numberOfSteps of numberOfSteps because this is always the last page. -->
  <h2 class="header">
    Step {{ getStepNumber }} of {{ getters.numberOfSteps }}: Directed
    Beneficiary
  </h2>

  <form @submit.prevent="next()">
    <p>
      If you die whilst invested in Crystal drawdown, your remaining pension
      fund can be used to provide either an income or a lump sum for one or more
      of your beneficiaries. In order that the trustee may consider your wishes
      in the event of your death please complete the following
    </p>
    <p class="mb-6">I wish to pass my remaining Crystal fund to:</p>

    <div v-for="(nominee, index) in nominees" :key="index">
      <div class="flex items-center justify-center my-4">
        <h2 class="flex-grow text-center">Nominee {{ index + 1 }}</h2>

        <div class="text-lg cursor-pointer" @click="removeNominee(index)">
          <i class="fas fa-minus-circle"></i>
        </div>
      </div>

      <div class="input-wrapper required">
        <label :for="`nomineeName-${index}`">Full name</label>
        <input
          :id="`nomineeName-${index}`"
          type="text"
          class="input"
          v-model="nominee.fullName"
          required
        />
      </div>

      <div class="input-wrapper">
        <label :for="`nomineeRelationship-${index}`">Relationship</label>
        <input
          :id="`nomineeRelationship-${index}`"
          type="text"
          class="input"
          v-model="nominee.relationship"
        />
      </div>

      <div class="input-wrapper required">
        <label :for="`nomineePercentage-${index}`">Percentage</label>
        <vs-input
          :id="`nomineePercentage-${index}`"
          type="number"
          filter="percentage"
          :value="nominee.percentage"
          @change="nominee.percentage = $event"
        />
      </div>

      <div class="input-wrapper required">
        <label :for="`nomineeAddressLine1-${index}`">Address line 1</label>
        <input
          :id="`nomineeAddressLine1-${index}`"
          type="text"
          class="input"
          v-model="nominee.addressLine1"
          required
          autocomplete="none"
        />
      </div>

      <div class="input-wrapper">
        <label :for="`nomineeAddressLine2-${index}`">Address line 2</label>
        <input
          :id="`nomineeAddressLine2-${index}`"
          type="text"
          class="input"
          v-model="nominee.addressLine2"
          autocomplete="none"
        />
      </div>

      <div class="input-wrapper required">
        <label :for="`nomineeAddressCity-${index}`">City</label>
        <input
          :id="`nomineeAddressCity-${index}`"
          type="text"
          class="input"
          v-model="nominee.addressCity"
          required
          autocomplete="none"
        />
      </div>

      <div class="input-wrapper">
        <label :for="`nomineeAddressCounty-${index}`">County</label>
        <input
          :id="`nomineeAddressCounty-${index}`"
          type="text"
          class="input"
          v-model="nominee.addressCounty"
          autocomplete="none"
        />
      </div>

      <div class="input-wrapper required">
        <label :for="`nomineeAddressPostcode-${index}`">Postcode</label>
        <input
          :id="`nomineeAddressPostcode-${index}`"
          type="text"
          class="input"
          v-model="nominee.addressPostCode"
          required
          autocomplete="none"
          pattern="([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})"
        />
      </div>

      <div class="input-wrapper">
        <label :for="`nomineeAddressCountry-${index}`">Country</label>
        <input
          :id="`nomineeAddressCountry-${index}`"
          type="text"
          class="input"
          v-model="nominee.addressCountry"
          autocomplete="none"
        />
      </div>
    </div>

    <div>
      <button type="button" @click="addNominee()" class="mb-4">
        <i class="fas fa-plus-circle"></i> Add a beneficiary
      </button>
    </div>

    <div class="check-group">
      <div class="mt-2">
        <label for="accept-investment-risks" class="text-sm">
          I confirm I have read and accept the
          <a
            href="http://evolve-website.clay10creative.com/members/crystal/investments#risks"
            target="_blank"
            >Investment Risks</a
          >
        </label>
        <input id="accept-investment-risks" type="checkbox" required />
      </div>

      <div class="mt-2">
        <label for="guidance-available" class="text-sm">
          I understand that I there is guidance available from
          <a
            href="https://www.moneyhelper.org.uk/en/pensions-and-retirement/pension-wise"
            target="_blank"
            >PensionWise from MoneyHelper
          </a>
        </label>
        <input id="guidance-available" type="checkbox" required />
      </div>

      <div class="mt-2">
        <label for="information-accurate" class="text-sm">
          I confirm that the information provided on this application is
          accurate. I understand that providing incorrect information may delay
          my application.
        </label>
        <input id="information-accurate" type="checkbox" required />
      </div>
    </div>

    <div v-if="error" class="my-4 text-sm">
      <p class="error">{{ error }}</p>
    </div>

    <div class="button-group">
      <button type="button" class="button" @click="back()">
        <i class="fas fa-arrow-left"></i> Back
      </button>
      <button type="submit" class="button">
        Submit form <i class="fas fa-arrow-right"></i>
      </button>
    </div>
  </form>
</template>

<script lang="ts">
import { computed, defineComponent, ref, onBeforeUnmount, nextTick } from "vue";
import store, { Nominee } from "@/store/";
import router, { Routes } from "@/router";

export default defineComponent({
  name: "Step 6",
  components: {},
  setup() {
    const error = ref<string>("");
    const nominees = ref<Nominee[]>(store.state.step6.nominees);

    onBeforeUnmount(() => {
      store.commit("setNominees", nominees.value);
    });

    const addNominee = () => {
      if (nominees.value.length < 10) {
        nominees.value.push(new Nominee());

        nextTick(() => {
          const pca: any = (window as any).pca;
          const nomineeValue = nominees.value.length - 1;

          const fields = [
            {
              element: `nomineeAddressLine1-${nomineeValue}`,
              field: "Line1",
            },
            {
              element: `nomineeAddressLine2-${nomineeValue}`,
              field: "Line2",
            },
            {
              element: `nomineeAddressCity-${nomineeValue}`,
              field: "City",
            },
            {
              element: `nomineeAddressCounty-${nomineeValue}`,
              field: "Province",
            },
            {
              element: `nomineeAddressPostcode-${nomineeValue}`,
              field: "PostalCode",
            },
            {
              element: `nomineeAddressCountry-${nomineeValue}`,
              field: "CountryName",
            },
          ];

          const control = new pca.Address(fields, {
            key: store.state.control.loqate,
            suppressAutocomplete: false,
          });

          control.listen("populate", (address: any) => {
            nominees.value[nomineeValue].addressLine1 = address.FormattedLine1;
            nominees.value[nomineeValue].addressLine2 = address.FormattedLine2;
            nominees.value[nomineeValue].addressCity = address.City;
            nominees.value[nomineeValue].addressCounty = address.Province;
            nominees.value[nomineeValue].addressPostCode = address.PostalCode;
            nominees.value[nomineeValue].addressCountry = address.CountryName;
          });
        });

        error.value = "";
      } else {
        error.value = "You already have the maximum number of nominees (10)";
      }
    };

    const removeNominee = (index: number) => {
      nominees.value.splice(index, 1);
      error.value = "";
    };

    const back = () => {
      router.go(-1);
      // router.push({ name: Routes.Step5 });
    };

    const next = () => {
      if (nominees.value.length > 0) {
        const total = nominees.value
          .map((i) => i.percentage)
          .reduce((a, b) => a + b);

        if (total === 100) {
          router.push({ name: Routes.Submit });
        } else {
          error.value = "Allocation must equal 100%";
        }
      } else {
        error.value = "You must nominate at least 1 beneficiary.";
      }
    };

    const getStepNumber = computed(() => {
      return store.state.data.totalFundValue < 10000 ? 5 : 6;
    });

    return {
      addNominee,
      removeNominee,
      nominees,
      back,
      next,
      error,
      getters: store.getters,
      getStepNumber,
    };
  },
});
</script>
